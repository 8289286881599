import styled from 'styled-components';

export const AddressDataContainer = styled.div`
  display: flex;
  gap: 40px;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const AddressDataInputContainer = styled.div``;
