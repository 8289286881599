/* eslint-disable react-hooks/exhaustive-deps */
import SideBar from '../CoverageStep/SideBar';
import { useState } from 'react';
import ReactLoading from 'react-loading';
import { Button } from 'components';
import { Container, SectionTitle } from '../../style';
import { ClientDataContainer, Content, ResumeArea } from './style';
import { formatPayMethod } from 'utils/payMethod';
import ClientData from './Forms/ClientData';
import MainDriver from './Forms/MainDriver';
import VehicleData from './Forms/VehicleData';
import PaymentData from './Forms/PaymentData';
import { formatCurrency, formatPlate } from 'react-data-formatter';
import { primary } from 'styles/colorProvider';
import { ConfirmDialog } from 'components/index';

const ProposalStep = ({
  quotationData,
  setQuotationData,
  quotationResponse,
  loadingProposal,
  dataCoverage,
  percentCommission,
  percentDiscount,
  sliderHandle,
  handleProposal,
  proposalResponse,
  verifyVehicleAuction,
  setShowAuctionModal,
  showAuctionModal,
  loadingVerifyVehicleAuction,
}) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const isCpf = quotationData?.client?.document?.length <= 14;

  const renderConfirmAuctionVehicleModal = () => (
    <ConfirmDialog
      open={showAuctionModal}
      onCancel={() => !loadingProposal && setShowAuctionModal(false)}
      title="AVISO - Veiculo de leilão"
      content="Somente 70% da Fipe do veiculo será segurado, deseja continuar?"
      onConfirm={handleProposal}
      loading={loadingProposal}
    />
  );

  return (
    <Container>
      {renderConfirmAuctionVehicleModal()}
      <SectionTitle>Emitir proposta</SectionTitle>
      <Content style={{ width: '100%' }}>
        <ClientDataContainer>
          {/* DADOS DO PROPRIETARIO */}
          <ClientData quotationData={quotationData} setQuotationData={setQuotationData} />

          {/* CONDUTOR PRINCIPAL */}
          <MainDriver quotationData={quotationData} setQuotationData={setQuotationData} />

          {/* DADOS DO VEICULO */}
          <VehicleData quotationData={quotationData} setQuotationData={setQuotationData} />

          {/* DADOS DE PAGAMENTO */}
          <PaymentData quotationData={quotationData} setQuotationData={setQuotationData} />
        </ClientDataContainer>
        <div>
          <SideBar
            quotationData={quotationData}
            setQuotationData={setQuotationData}
            quotationResponse={quotationResponse}
            proposalResponse={proposalResponse}
            dataCoverage={dataCoverage}
            sliderHandle={sliderHandle?.handle}
            percentCommission={percentCommission}
            percentDiscount={percentDiscount}
            grievance_percent={sliderHandle.grievance_percent}
            discount_percent={sliderHandle.discount_percent}
            type="proposal"
            confirmButtonHandleClick={() => {
              setDialogIsOpen(true);
            }}
          />

          <Button
            disabled={
              loadingProposal ||
              (!!quotationData?.vehicle?.zero_km && !quotationData?.vehicleInvoice) ||
              !quotationData?.client?.name ||
              !quotationData?.client?.document ||
              !quotationData?.client?.email ||
              !quotationData?.client?.phone ||
              !quotationData?.client?.ppe ||
              (isCpf &&
                (!quotationData?.client?.birthdate ||
                  !quotationData?.client?.civil_status ||
                  !quotationData?.client?.gender ||
                  !quotationData?.client?.cnh_number ||
                  !quotationData?.client?.cnh_date ||
                  !quotationData?.client?.cnh_expiry ||
                  !quotationData?.client?.cnh_category ||
                  !quotationData?.client?.rg_number ||
                  !quotationData?.client?.rg_oe ||
                  !quotationData?.mainDriver?.birthdate ||
                  !quotationData?.mainDriver?.civil_status ||
                  !quotationData?.mainDriver?.gender ||
                  !quotationData?.mainDriver?.cnh_number ||
                  !quotationData?.mainDriver?.cnh_date ||
                  !quotationData?.mainDriver?.cnh_expiry ||
                  !quotationData?.mainDriver?.cnh_category)) ||
              !quotationData?.mainDriver?.name ||
              !quotationData?.mainDriver?.document ||
              !quotationData?.address?.zip_code ||
              !quotationData?.address?.street ||
              !quotationData?.address?.number ||
              !quotationData?.address?.district ||
              !quotationData?.address?.state ||
              (!quotationData?.vehicle?.plate &&
                (!quotationData?.vehicle?.chassi || quotationData?.vehicle?.chassi.length !== 17)) ||
              !quotationData?.vehicle?.renavam ||
              !quotationData?.vehicle?.brand ||
              !quotationData?.vehicle?.model ||
              !quotationData?.vehicle?.vehicle_type ||
              !quotationData?.vehicle?.build_year ||
              !quotationData?.vehicle?.year_model ||
              !quotationData?.vehicle?.color ||
              !quotationData?.vehicle?.vehicle_use ||
              !quotationData?.vehicle?.id_color ||
              quotationData?.vehicle?.vehicle_already_insured === null ||
              quotationData?.vehicle?.some_driver_18_25_years_old === null ||
              (quotationData?.payment?.pay_method === '1' &&
                (!quotationData?.payment?.number ||
                  !quotationData?.payment?.name ||
                  !quotationData?.payment?.expiry ||
                  !quotationData?.payment?.cvc))
            }
            height="35px"
            width="260px"
            onClick={verifyVehicleAuction}
            buttonBoxShadowColor="transparent"
            borderRadius="10px"
          >
            {!loadingVerifyVehicleAuction && 'Gerar proposta'}
            {loadingVerifyVehicleAuction && <ReactLoading color={primary} height={24} width={24} type="spin" />}
          </Button>
        </div>
      </Content>
    </Container>
  );
};

export default ProposalStep;
