import React, { useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { UploadButton } from './styles';
import { toast } from 'react-toastify';

const UploadFileInput = ({ onFileSelect, onFileRemove, label, text, acceptedExtensions, required, ...props }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size > 5 * 1024 * 1024) {
      return toast.error('O arquivo excede o tamanho máximo de 5MB.', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
    setSelectedFile(file);
    onFileSelect(file);
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    onFileRemove();
  };

  return (
    <div>
      {!!label && <p style={{ fontSize: 12 }}>{label}:</p>}
      <UploadButton component="label" startIcon={<AttachFileIcon />} showRequiredError={required && !selectedFile} {...props}>
        {text || 'Selecionar arquivo'}
        <input type="file" style={{ display: 'none' }} onChange={handleFileChange} accept={acceptedExtensions.join(',')} />
      </UploadButton>
      {selectedFile && (
        <div>
          <Typography variant="caption">{selectedFile.name}</Typography>
          <Button size="small" onClick={handleRemoveFile} startIcon={<DeleteOutlineIcon style={{ color: 'red' }} />} />
        </div>
      )}
    </div>
  );
};

export default UploadFileInput;
