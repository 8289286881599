import styled from 'styled-components';
import { primary, secondary } from 'styles/colorProvider';

export const ProductsArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
`;

export const ProductItem = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  font-size: 12px;
  text-align: start;
  font-weight: bold;
  margin: 10px;
  color: ${({ selected }) => {
    if (selected) {
      return 'white';
    } else {
      return 'rgb(141 172 201)';
    }
  }};
  background: ${({ selected }) => {
    if (selected) {
      return 'rgb(73 204 122)';
    } else {
      return '#fefefe';
    }
  }};
  min-height: ${(props) => props.height || '60px'};
  width: 250px;
  cursor: ${({ disabled, hasVariations }) => !disabled && !hasVariations && 'pointer'};
  border: ${({ selected }) => {
    if (selected) {
      return '2px solid white';
    } else {
      return '2px solid transparent';
    }
  }};
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  box-shadow: ${({ disabled, selected }) => {
    if (disabled) {
      return '0px 0px 3px 0px rgba(100, 100, 111, 0.2)';
    }
    if (selected) {
      return '0px 0px 5px 3px rgba(73, 204, 122, 0.5)';
    } else {
      return '3px 3px 5px 3px rgba(100, 100, 111, 0.2)';
    }
  }};

  &&:hover {
    box-shadow: ${({ hasVariations, disabled, selected, grouped }) => {
      if (disabled || grouped || hasVariations) {
        return '0px 0px 3px 0px rgba(100, 100, 111, 0.2)';
      } else if (selected) {
        return '0px 0px 8px 3px rgba(73, 204, 122, 0.8)';
      } else {
        return '3px 3px 8px 3px rgba(100, 100, 111, 0.8)';
      }
    }};
  }

  @media (max-width: 1000px) {
    width: 80%;
  }
  cursor: ${({ disabled, grouped, waiting }) => {
    if (disabled) {
      return 'not-allowed';
    } else if (waiting) {
      return 'wait';
    } else if (grouped) {
      return 'pointer';
    }
  }};
`;

export const ProductItemNameContainer = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  height: 100%;
  > div {
    display: flex;
    gap: 4px;
  }
`;

export const ProductItemButton = styled.div`
  border: none;
  border-radius: 5px;
  padding: 1px 3px;
  font-size: 10px;
  color: ${({ isChecked }) => (isChecked ? 'white' : primary)};
  font-weight: bolder;
  background: ${({ isChecked }) => (isChecked ? primary : '#fff')};
  cursor: pointer;
  margin-right: 5px;
  :hover {
    cursor: ${({ disabled, grouped, waiting }) => {
      if (disabled) {
        return 'not-allowed';
      } else if (waiting) {
        return 'wait';
      } else if (grouped) {
        return 'pointer';
      }
    }};
    transform: ${({ disabled }) => (!!disabled ? 'unset' : 'scale(1.05)')};
    box-shadow: ${({ disabled }) => (!!disabled ? 'unset' : '0 0 3px 0 gray')};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
`;

export const OptionsSide = styled.div`
  flex: 1 1 800px;
`;

export const VehicleDataSide = styled.div`
  flex: 0 1 auto;
`;

export const CheckIconContaner = styled.div`
  flex: 0 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 100%;
  box-shadow: 1px 1px 3px 0 gray inset;
  background: #e3eef6;
  svg {
    opacity: ${({ selected }) => (selected ? '1' : '0')};
    transition: 0.3s;
  }
`;

export const CoveragesArea = styled.div`
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  width: 100% !important;
  max-width: 100% !important;
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
  /* background-color: #e9f2fa; */
  border-radius: 10px;
  box-shadow: 3px 3px 6px 1px rgb(95 157 231 / 28%);

  h4 {
    font-size: 24px;
    margin: 0px;
    color: #0cb863;
  }
  h5 {
    font-size: 14px;
    color: #0cb863;
    margin: 0;
  }
  p {
    font-size: 14px;
    font-weight: bold;
    color: #7c9fbf;
    margin: 0;
  }

  .value {
    margin: 0px;
    font-size: 24px;
    color: ${secondary};
    font-weight: bold;
  }

  .value-label {
    font-size: 15px;
    color: #121212;
    font-weight: normal;
  }

  .button-area {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .vertical-button-area {
    flex-direction: column;
  }
`;
