import React, { useState } from 'react';

import logo from '../../assets/logo.png';
import simbolo from '../../assets/simboloLogoBranca.PNG';
import { CgLogIn } from 'react-icons/cg';
import { RiInstagramFill } from 'react-icons/ri';
import { RiLinkedinBoxFill } from 'react-icons/ri';
import LoginModal from '../../components/Login';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';

import {
  Container,
  TopBar,
  Header,
  Line,
  Line3,
  Logo,
  Nav,
  Login,
  Footer,
  Simbolo,
  Title,
  Line2,
  Text,
  Subtitle,
  Item1,
} from './styles';

Modal.setAppElement('#root');

function Index() {
  const [modalLoginIsOpen, setModalLoginIsOpen] = useState(false);

  const history = useHistory();

  const handleOpenModalLogin = async () => {
    setModalLoginIsOpen(true);
  };
  const handleCloseModalLogin = () => {
    setModalLoginIsOpen(false);
  };

  return (
    <>
      <Container>
        <TopBar>
          <Line>
            <Logo
              src={logo}
              onClick={() => {
                history.push('/');
              }}
            />
          </Line>
        </TopBar>
        <Header>
          <Line2>
            <Title>
              PARA VOCÊ ENTENDER MELHOR <br />O QUE FAZEMOS COM AS SUAS INFORMAÇÕES
            </Title>

            <Text>
              Fizemos o máximo para explicar de forma clara e simples quais dados pessoais precisaremos de você e o que vamos
              fazer com cada um deles. Por isso, separamos abaixo os pontos mais importantes, que também podem ser lidos de forma
              bem completa e detalhada nas nossas
              <span>
                {' '}
                <a href="#termoDeUso" className="link">
                  Políticas de Privacidade
                </a>{' '}
              </span>
              .
            </Text>

            <Text>
              Além disso, estamos sempre disponíveis para tirar qualquer dúvida que você tenha por meio do e-mail
              <span className="semiBold noCursor"> contato@splitrisk.com.br</span>,
              <span>
                {' '}
                <a href="https://www.instagram.com/splitrisk.app/" target="blank" className="link">
                  Instagram
                </a>{' '}
              </span>
              ou pelo
              <span>
                {' '}
                <a href="https://br.linkedin.com/company/split-risk" target="blank" className="link">
                  {' '}
                  Linkedin
                </a>{' '}
              </span>
              .
            </Text>

            <Subtitle>1) Como faremos a segurança de seus dados?</Subtitle>

            <Text>
              Para garantir a sua segurança, seus dados pessoais são transferidos de forma criptografada e armazenados em
              servidores{' '}
              <span>
                {' '}
                <a href="https://tecnoblog.net/responde/o-que-e-a-aws-amazon-web-services/" target="blank" className="link">
                  AWS Amazon
                </a>{' '}
              </span>
              . Além disso, utilizamos de uma variedade de tecnologias e procedimentos de segurança para ajudar a proteger as
              informações dos Usuários.
              <span className="semiBold noCursor">
                {' '}
                Com isso, essas empresas passam a ter acesso aos seus dados, somente para armazená-los, assim que você os fornece
                à SplitRisk
              </span>
              . Se você tiver algum problema com isso, pedimos que não continue utilizando o site.
            </Text>

            <Subtitle>2) Quais dados precisa nos informar em nosso site?</Subtitle>
            <Text>
              Para o cadastro e utilização dos serviços da <span className="semiBold noCursor">SplitRisk </span>
              serão requeridas as seguintes informações: nome, e-mail, número do WhatsApp, CEP e a placa do veículo.
            </Text>

            <Subtitle>3) Com quem compartilhamos seus dados pessoais?</Subtitle>
            <Text>
              Nós iremos compartilhar os seus dados pessoais com as empresas referenciadas nestas políticas, com o objetivo de
              aprimoramento e execução dos serviços da <span className="semiBold noCursor">SplitRisk</span>. Salvo nos casos
              citados, em caso de consentimento legal do titular dos dados pessoais e por força de ordem judicial ou determinação
              legal, nós não iremos compartilhar seus dados com terceiros.
            </Text>

            <Subtitle>4) Seus registros de acesso serão coletados?</Subtitle>
            <Text>
              Quando você entra em nosso site, colhemos seus registros de acesso, ou seja, conjunto de informações referentes à
              data e hora de uso de uma determinada aplicação de internet a partir de um determinado endereço IP, estas
              informações serão mantidas pela
              <span className="semiBold noCursor"> SplitRisk</span>, sob sigilo, em ambiente controlado e de segurança, pelo prazo
              mínimo de 06 (seis) meses, nos termos da Lei n° 12.965/2014, e artigo 7º, II, da Lei 13709/18.
            </Text>

            <Subtitle>5) Existe coleta indireta de dados?</Subtitle>
            <Text>
              Além dos registros de acesso, podemos coletar informações de forma indireta por meio de cookies, conforme o descrito
              nas Políticas de Privacidade. Alguns destes cookies são indispensáveis para o funcionamento do site.
            </Text>

            <Subtitle>6) Registros de comunicações serão armazenados?</Subtitle>
            <Text>
              Nós vamos armazenar também todas as conversas que você tiver conosco em nossos canais de comunicação, pois isso irá
              melhorar o seu atendimento e torná-lo mais eficiente. Sem esse histórico, provavelmente todas as vezes que você
              utilizasse o canal teria que repetir o que já nos passou anteriormente.
            </Text>

            <Subtitle>7) Como seus dados serão tratados?</Subtitle>
            <Text>
              Todos os seus dados são tratados com finalidades específicas e de acordo com a Lei de Proteção de Dados Pessoais.
              Todas essas informações estão descritas em uma tabela, para facilitar a sua visualização, nas nossas Políticas de
              Privacidade.
            </Text>

            <Subtitle>8) Quais são seus direitos?</Subtitle>
            <Text>
              Mesmo que você já tenha nos fornecido seus dados pessoais, você possui total direito de, a qualquer momento:
              confirmar a existência de tratamento dos seus dados; acessar os seus dados; corrigir os seus dados; tornar anônimo
              os dados; bloquear ou eliminar os dados desnecessários, excessivos ou tratados em desconformidade com a Lei; pedir a
              portabilidade dos dados a outro fornecedor; eliminar dados, exceto aqueles exigidos por lei; obter informação sobre
              quem a<span className="semiBold noCursor"> SplitRisk </span>realizou uso compartilhado de dados; obter informação
              sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa; e voltar atrás e revogar o
              seu consentimento.
            </Text>

            <Subtitle>9) Essas condições poderão mudar?</Subtitle>
            <Text>
              Nossa <span className="semiBold noCursor"> Política de Privacidade </span>poderá mudar, mas você sempre poderá
              acessar a versão mais atualizada em nosso site. Além disso, se formos realizar alguma ação que a lei exija sua
              autorização, você receberá um aviso antes para poder aceitar ou recusar.
            </Text>

            <Subtitle>10) Qual é o conteúdo das Políticas de Privacidade?</Subtitle>
            <Text>
              A Política de Privacidade a seguir está dividida da seguinte forma para facilitar o seu acesso à informação:{' '}
            </Text>
            <Item1>
              {' '}
              a){' '}
              <span>
                <a href="#a" className="link">
                  Data de Disponibilização do Texto;
                </a>
              </span>
            </Item1>
            <Item1>
              {' '}
              b){' '}
              <span>
                <a href="#b" className="link">
                  Explicação dos Termos Técnicos ou em Língua Estrangeira;
                </a>
              </span>
            </Item1>
            <Item1>
              {' '}
              c){' '}
              <span>
                <a href="#c" className="link">
                  Privacidade do Usuário e Operadores de Dados Terceirizados;
                </a>
              </span>
            </Item1>
            <Item1>
              {' '}
              d){' '}
              <span>
                <a href="#d" className="link">
                  Coleta de Dados;
                </a>
              </span>
            </Item1>
            <Item1>
              {' '}
              e){' '}
              <span>
                <a href="#e" className="link">
                  Tratamento de Dados Pessoais;
                </a>
              </span>
            </Item1>
            <Item1>
              {' '}
              f){' '}
              <span>
                <a href="#f" className="link">
                  Compartilhamento dos Dados Pessoais dos Usuários;
                </a>
              </span>
            </Item1>
            <Item1>
              {' '}
              g){' '}
              <span>
                <a href="#g" className="link">
                  Cancelamento de Contas de Acesso e Exclusão de Dados;
                </a>
              </span>
            </Item1>
            <Item1>
              {' '}
              h){' '}
              <span>
                <a href="#h" className="link">
                  Direitos do Titular dos Dados Pessoais;
                </a>
              </span>
            </Item1>
            <Item1>
              {' '}
              i){' '}
              <span>
                <a href="#i" className="link">
                  Segurança;
                </a>
              </span>
            </Item1>
            <Item1>
              {' '}
              j){' '}
              <span>
                <a href="#j" className="link">
                  Mudanças na Política de Privacidade;
                </a>
              </span>
            </Item1>
            <Item1>
              {' '}
              k){' '}
              <span>
                <a href="#k" className="link">
                  Encarregado e Disposições Gerais;
                </a>
              </span>
            </Item1>
            <Item1>
              {' '}
              l){' '}
              <span>
                <a href="#l" className="link">
                  Contato.
                </a>
              </span>
            </Item1>

            <Title id="termoDeUso"> POLÍTICAS DE PRIVACIDADE </Title>
            <Text>
              Antes de acessar o site da <span className="semiBold noCursor"> SplitRisk</span>, é importante que você leia,
              entenda e aceite de forma livre, inequívoca e informada estas Políticas de Privacidade.
            </Text>
            <Text>
              Este site, cujo endereço é https://splitrisk.com.br/, é de propriedade, mantida e operada por Split Risk Seguradora
              S.A. (“SplitRisk”), sociedade empresária constituída na forma de Sociedade Limitada, inscrita no CNPJ sob o n°
              43.505.273/0001-09, com endereço na AV Gabriela Junqueira de Freitas, nº 392, Bairro dos Eucaliptos, CEP:
              38.414-126, na cidade de Uberlândia, no estado de Minas Gerais.
            </Text>
            <Text>
              O presente documento visa prestar informações sobre a coleta, utilização, tratamento e armazenamento dos dados
              fornecidos pelos Usuários e está em conformidade com a Lei 12.965/2014 (Marco Civil da Internet) e com a Lei nº
              13.709/18 (Lei Geral de Proteção de Dados).
            </Text>

            <Subtitle id="a">1. Data de disponibilização do texto</Subtitle>
            <Text className="textTab1">1.1. A presente versão deste documento foi disponibilizada em: 04/10/2021.</Text>

            <Subtitle id="b">2. Explicação dos termos técnicos ou língua estrangeira</Subtitle>
            <Text className="textTab1">
              2.1. Abaixo estão dispostos os significados das nomenclaturas técnicas e termos na língua inglesa.
            </Text>
            <Text className="textTab2 decreaseMarginTop">
              <li>
                <span className="semiBold">Controlador: </span>Pessoa natural ou jurídica, de direito público ou privado, a quem
                competem as decisões referentes ao tratamento de dados pessoais;
              </li>
            </Text>

            <Text className="textTab2 decreaseMarginTop">
              <li>
                <span className="semiBold">Cookies: </span>Pequenos arquivos de texto que ficam gravados no computador do
                internauta e podem ser recuperados pelo site que o enviou durante a navegação. São utilizados principalmente para
                identificar e armazenar informações sobre os visitantes.
              </li>
            </Text>

            <Text className="textTab2 decreaseMarginTop">
              <li>
                <span className="semiBold">Criptografia: </span>Conjunto de princípios e técnicas para cifrar a escrita, torná-la
                ininteligível para os que não tenham acesso às convenções combinadas.
              </li>
            </Text>

            <Text className="textTab2 decreaseMarginTop">
              <li>
                <span className="semiBold">Dado pessoal: </span>Informação relacionada a pessoa natural identificada ou
                identificável;
              </li>
            </Text>

            <Text className="textTab2 decreaseMarginTop">
              <li>
                <span className="semiBold">Dado pessoal sensível: </span>Dado pessoal sobre origem racial ou étnica, convicção
                religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político,
                dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural;
              </li>
            </Text>

            <Text className="textTab2 decreaseMarginTop">
              <li>
                <span className="semiBold">Encarregado: </span>Pessoa indicada pelo controlador e operador para atuar como canal
                de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD).
              </li>
            </Text>

            <Text className="textTab2 decreaseMarginTop">
              <li>
                <span className="semiBold">IP (ou Internet Protocol): </span>Identificação única para cada computador conectado a
                uma rede.
              </li>
            </Text>

            <Text className="textTab2 decreaseMarginTop">
              <li>
                <span className="semiBold">Operador: </span>Pessoa natural ou jurídica, de direito público ou privado, que realiza
                o tratamento de dados pessoais em nome do controlador;
              </li>
            </Text>

            <Text className="textTab2 decreaseMarginTop">
              <li>
                <span className="semiBold">Tratamento de dados: </span>Toda operação realizada com dados pessoais, como as que se
                referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição,
                processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação,
                comunicação, transferência, difusão ou extração.
              </li>
            </Text>

            <Text className="textTab2 decreaseMarginTop">
              <li>
                <span className="semiBold">Usuário(s): </span>Aquele(s) que utiliza(m) o nosso site.
              </li>
            </Text>

            <Subtitle id="c">3. Privacidade do usuário e operadores de dados terceirizados</Subtitle>
            <Text className="textTab1 decreaseMarginBottom">
              3.1. Proteger sua privacidade é muito importante para nós. Seus dados pessoais são transferidos de forma
              criptografada e armazenados em servidores{' '}
              <span>
                <a href="https://tecnoblog.net/responde/o-que-e-a-aws-amazon-web-services/" className="link">
                  AWS Amazon
                </a>{' '}
              </span>
              . Além disso, utilizamos de uma variedade de tecnologias e procedimentos de segurança para ajudar a proteger as
              informações dos Usuários.
            </Text>

            <Text className="textTab2 decreaseMarginBottom">
              3.1.1. Esses servidores são independentes e não possuem relação com o presente texto. Sendo assim, recomendamos que
              você também leia os termos de uso e políticas de privacidade dessas plataformas e veja se concorda com todas as
              disposições, antes de utilizar nossos serviços.
            </Text>

            <Text className="textTab1 decreaseMarginBottom">
              3.2. Os servidores utilizados pela <span className="semiBold noCursor">SplitRisk</span> são munidos de mecanismos
              aptos a assegurar a segurança de seus dados, estão localizados fora do Brasil, nos Estados Unidos, e são utilizados
              para que a <span className="semiBold noCursor">SplitRisk </span>
              possa executar o seu serviço de forma adequada, conforme artigo 33, IX da Lei n. 13709/18.
            </Text>

            <Text className="textTab1 decreaseMarginBottom">
              3.3. Todos os registros de acesso, conjunto de informações referentes à data e hora de uso de uma determinada
              aplicação de internet a partir de um determinado endereço IP, serão mantidos pela{' '}
              <span className="semiBold noCursor">SplitRisk</span>, sob sigilo, em ambiente controlado e de segurança, pelo prazo
              mínimo de 06 (seis) meses, nos termos da Lei n. 12.965/2014, e artigo 7º, II, da Lei 13709/18.
            </Text>

            <Text className="textTab1 decreaseMarginBottom">
              3.4. O Usuário deve se responsabilizar e se declarar exclusivo responsável por todas as ações, bem como por todas as
              informações e a veracidade do conteúdo que inserir no site.
            </Text>

            <Subtitle id="d" className="increaseMarginTop">
              4. Coleta de dados
            </Subtitle>
            <Text className="textTab1 decreaseMarginBottom">
              4.1.{' '}
              <span>
                <a className="link">Dados fornecidos pelo Usuário</a>
              </span>
              : Para o cadastro e utilização dos serviços da <span className="semiBold noCursor">SplitRisk </span>
              serão requeridas as seguintes informações: nome, e-mail, número do WhatsApp, CEP e a placa do veículo.
            </Text>
            <Text className="textTab1 decreaseMarginBottom">
              4.2.{' '}
              <span>
                <a className="link">Informações que coletamos de forma indireta</a>
              </span>
              : Além dos registros de acesso, coletamos informações de forma indireta através de cookies, conforme a seguir:
            </Text>

            <Text className="textTab2 decreaseMarginBottom">
              4.2.1. Cookies de Terceiros: São os cookies utilizados para melhorar o desempenho da{' '}
              <span className="semiBold noCursor">SplitRisk </span>e suas estratégias de marketing, com ajuda de empresas
              parceiras, mas independentes, como:
              <span>
                <a className="link"> Facebook Ads</a>
              </span>{' '}
              e{' '}
              <span>
                <a className="link">Google Ads</a>
              </span>
              , coletando informações de: Tempo de visita, páginas acessadas, downloads efetuados e número de IP.
            </Text>

            <Text className="textTab2 decreaseMarginBottom">
              4.2.2. Rejeição ou exclusão de cookies: O Usuário poderá fazer o gerenciamento dos cookies através de seu navegador,
              que muitas vezes pode já ter autorizações padrões. As permissões e autorizações também podem ser alteradas
              diretamente nas configurações de sua conta junto a estas empresas.
            </Text>

            <Text className="textTab1 decreaseMarginBottom">
              4.3.{' '}
              <span>
                <a className="link"> Histórico de contato:</a>
              </span>{' '}
              A <span className="semiBold noCursor">SplitRisk</span> armazena informações a respeito de todas as interações já
              realizadas entre os Usuários por meio do site, como mensagens em e-mails, contatos telefônicos e upload de arquivos,
              pois isso irá melhorar o seu atendimento e torná-lo mais eficiente. Sem esse histórico, provavelmente todas as vezes
              que você utilizasse o canal teria que repetir o que já nos passou anteriormente.
            </Text>

            <Subtitle id="e" className="increaseMarginTop">
              5.Tratamento de dados pessoais
            </Subtitle>
            <Text className="textTab1 decreaseMarginBottom">
              5.1. Ao aceitar a presente política de privacidade, o Usuário compreende que a{' '}
              <span className="semiBold noCursor">SplitRisk</span> realiza o tratamento de seus dados pessoais.
            </Text>

            <div className="table">
              <table>
                <thead className="table-title">
                  <tr>
                    <th>Tipo de dado pessoal</th>
                    <th>Base legal</th>
                    <th>Finalidade</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <Subtitle>Nome, e-mail e número do WhatsApp</Subtitle>
                    </td>
                    <td>
                      <Text>
                        Necessário para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual
                        seja parte o titular, a pedido do titular dos dados (Art. 7º, V, Lei nº 13.709/2018).
                      </Text>
                    </td>

                    <td>
                      <Text>
                        Usado para identificação do Usuário. Trata-se de dado pessoal essencial para que seja possível entrar em
                        contato com o Usuário.
                      </Text>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Subtitle>Placa do veículo</Subtitle>
                    </td>
                    <td>
                      <Text>
                        Necessário para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual
                        seja parte o titular, a pedido do titular dos dados (Art. 7º, V, Lei nº 13.709/2018).
                      </Text>
                    </td>

                    <td>
                      <Text>
                        Usada para a identificação da marca e modelo do veículo para possibilitar a prestação do serviço.
                      </Text>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Subtitle>CEP</Subtitle>
                    </td>
                    <td>
                      <Text>
                        Necessário para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual
                        seja parte o titular, a pedido do titular dos dados (Art. 7º, V, Lei nº 13.709/2018).
                      </Text>
                    </td>
                    <td>
                      <Text>Utilizado para identificação da localização do Usuário.</Text>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Subtitle>Localização</Subtitle>
                    </td>
                    <td>
                      <Text>
                        Necessário para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual
                        seja parte o titular, a pedido do titular dos dados (Art. 7º, V, Lei nº 13.709/2018).
                      </Text>
                    </td>
                    <td>
                      <Text>Utilizado para entrar em contato com o Usuário.</Text>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Subtitle>
                        IP (Internet Protocol), Localização,Fonte de referência,Tipo de navegador,Duração da visita,Páginas
                        visitadas
                      </Subtitle>
                    </td>
                    <td>
                      <Text>
                        Cumprimento de obrigação legal ou regulatória pelo controlador (Art. 7º, II, Lei nº 13.709/2018).
                      </Text>
                    </td>
                    <td>
                      <Text>
                        Obediência ao artigo 15 da Lei n. 12.965/2014, que impõe o dever da{' '}
                        <span className="semiBold noCursor">SplitRisk</span>
                        de manter os respectivos registros de acesso a aplicações de internet, sob sigilo, em ambiente controlado
                        e de segurança, pelo prazo de 6 (seis) meses.
                      </Text>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <Subtitle id="f" className="increaseMarginTop">
              6. Compartilhamento dos dados dos usuários
            </Subtitle>
            <Text className="textTab1 decreaseMarginBottom">
              6.1. Poderão ter acesso interno às informações dos Usuários apenas pessoas estritamente necessárias para a prestação
              do serviço.
            </Text>

            <Text className="textTab1 decreaseMarginBottom">
              6.2. Os dados dos Usuários serão compartilhados pela SplitRisk as seguintes operadoras, e pelas seguintes
              finalidades:
            </Text>

            <Text className="textTab2 decreaseMarginBottom">
              6.2.1.{' '}
              <span>
                <a href="https://tecnoblog.net/responde/o-que-e-a-aws-amazon-web-services/" className="link">
                  AWS Amazon
                </a>{' '}
              </span>
              : uma vez que os dados pessoais ficam armazenados em seus servidores;.
            </Text>
            <Text className="textTab2 decreaseMarginBottom">
              6.2.2. A partir do momento que estas empresas tiverem acesso a estes dados, se tornarão responsáveis pela segurança,
              tratamento e compartilhamento adequado dessas informações,{' '}
              <span>
                <a className="link">
                  não podendo divulgá-las para outras finalidades, em desconformidade com a legislação vigente ou com estas
                  Políticas de Privacidade, sob pena de responderem por todas as punições, em especial as de natureza cível,
                  criminal e as aplicadas pela Autoridade Nacional de Proteção de Dados.
                </a>{' '}
              </span>
            </Text>

            <Text className="textTab1 decreaseMarginBottom">
              6.3. Salvo nos casos citados, em caso de consentimento legal do titular dos dados pessoais e por força de ordem
              judicial ou determinação legal, nós não iremos compartilhar seus dados com terceiros.
            </Text>

            <Subtitle id="g" className="increaseMarginTop">
              7. Cancelamento de contas de acesso e exclusão de dados
            </Subtitle>
            <Text className="textTab1 decreaseMarginBottom">
              7.1. Cancelamento de contas de acesso pela <span className="semiBold noCursor">SplitRisk</span>: A{' '}
              <span className="semiBold noCursor">SplitRisk </span>
              poderá, a seu exclusivo critério, bloquear, restringir, desabilitar ou impedir o acesso de qualquer Usuário ao site
              sempre que for detectada uma conduta inadequada.
            </Text>

            <Text className="textTab1 decreaseMarginBottom">
              7.2.{' '}
              <span>
                <a className="link">
                  Exclusão de dados: Quando finda a finalidade de tratamento de dados ou diante de solicitação pelo e-mail
                  contato@splitrisk.com.br, o Usuário terá todos os seus dados deletados imediatamente e permanentemente, exceto
                  os dados cuja manutenção seja obrigatória por lei ou regulação, os dados necessários para o exercício regular de
                  direitos em processo judicial, administrativo ou arbitral, como os registros de acesso (conjunto de informações
                  referentes à data e hora de uso de uma determinada aplicação de internet a partir de um determinado endereço
                  IP), que serão mantidos, sob sigilo, em ambiente controlado e de segurança, pelo prazo de 6 (seis) meses, nos
                  termos da Lei n. 12.965/2014 e com a base legal do art. 7, II, da Lei Geral de Proteção de Dados Pessoais.
                </a>
              </span>
            </Text>

            <Subtitle id="h" className="increaseMarginTop">
              8. Direitos do titular dos dados pessoais
            </Subtitle>
            <Text className="textTab1 decreaseMarginBottom">
              8.1. O titular de dados pessoais tem direito a obter do controlador, em relação aos dados por ele tratados, a
              qualquer momento e mediante requisição:
            </Text>

            <Text className="textTab2 decreaseMarginBottom">8.1.1. Confirmação da existência de tratamento de dados;</Text>

            <Text className="textTab2 decreaseMarginBottom">8.1.2. Acesso aos dados;</Text>

            <Text className="textTab2 decreaseMarginBottom">
              8.1.3. Correção de dados incompletos, inexatos ou desatualizados;
            </Text>

            <Text className="textTab2 decreaseMarginBottom">
              8.1.4. Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o
              disposto na Lei 13.709/2018;
            </Text>

            <Text className="textTab2 decreaseMarginBottom">
              8.1.5. Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com
              a regulamentação da autoridade nacional, observados os segredos comercial e industrial;
            </Text>

            <Text className="textTab2 decreaseMarginBottom">
              8.1.6. Eliminação dos dados tratados com o consentimento do titular, exceto nas hipóteses previstas na Lei
              13.709/2018;
            </Text>

            <Text className="textTab2 decreaseMarginBottom">
              8.1.7. Informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;
            </Text>

            <Text className="textTab2 decreaseMarginBottom">
              8.1.8. Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;
            </Text>

            <Text className="textTab2 decreaseMarginBottom">8.1.9. Revogação do consentimento.</Text>

            <Subtitle id="i" className="increaseMarginTop">
              9. Segurança
            </Subtitle>
            <Text className="textTab1 decreaseMarginBottom">
              9.1. A <span className="semiBold noCursor">SplitRisk</span> tem o compromisso de preservar a estabilidade, segurança
              e funcionalidade do site, por meio de medidas técnicas compatíveis com os padrões internacionais e pelo estímulo ao
              uso de boas práticas. Todavia nenhum serviço disponível na internet possui total garantia contra invasões ilegais.
              Em casos em que terceiros não autorizados invadam o sistema de forma ilícita, a{' '}
              <span className="semiBold noCursor">SplitRisk</span> diligenciará da melhor forma para encontrar o responsável pela
              atuação ilícita, mas não se responsabiliza pelos danos por eles causados.
            </Text>

            <Subtitle id="j" className="increaseMarginTop">
              10. Mudança na pilítica de privacidade
            </Subtitle>
            <Text className="textTab1 decreaseMarginBottom">
              10.1. A <span className="semiBold noCursor">SplitRisk</span> poderá unilateralmente adicionar e/ou modificar
              qualquer cláusula contida nestas Políticas de Privacidade. A versão atualizada valerá para o uso do site realizado a
              partir de sua publicação. A continuidade de acesso ou utilização do site, depois da divulgação, confirmará a
              vigência das novas Políticas de Privacidade pelos Usuários.
            </Text>

            <Text className="textTab1 decreaseMarginBottom">
              10.2. Caso a mudança efetuada necessite de consentimento do Usuário, será apresentada a opção de aceitar de forma
              livre, inequívoca e informada o novo texto ou de recusá-lo.
            </Text>

            <Text className="textTab1 decreaseMarginBottom">
              10.3. Caso o Usuário não esteja de acordo com a alteração, poderá não fornecer consentimento para atos específicos
              ou poderá rescindir totalmente seu vínculo com a <span className="semiBold noCursor">SplitRisk</span>. Essa rescisão
              não eximirá, no entanto, o Usuário de cumprir com todas as obrigações assumidas sob as versões precedentes das
              Políticas de Privacidade.
            </Text>

            <Subtitle id="k" className="increaseMarginTop">
              11. Encarregado e disposições gerais
            </Subtitle>
            <Text className="textTab1 decreaseMarginBottom">
              11.1. A <span className="semiBold noCursor">SplitRisk</span> indica como encarregado o Sr. Rodrigo Bianchini, com
              endereço eletrônico rodrigo.bianchini@splitrisk.com.br, nos termos do art. 41 da Lei Geral de Proteção de Dados,
              para aceitar reclamações e comunicações dos titulares e da Autoridade Nacional de Proteção de Dados, prestar
              esclarecimentos e adotar providências.
            </Text>

            <Text className="textTab1 decreaseMarginBottom">
              11.2. A <span className="semiBold noCursor">SplitRisk</span> dispõe de um texto específico para regular a licença de
              uso, os direitos, deveres, garantias e disposições gerais: os Termos de Uso. Todos esses documentos integram
              inseparavelmente estas Políticas de Privacidade.
            </Text>

            <Subtitle id="m" className="increaseMarginTop">
              12. Contato
            </Subtitle>
            <Text className="textTab1 decreaseMarginBottom">
              12.1. A SplitRisk disponibiliza os seguintes canais para receber todas as comunicações que os Usuários desejarem
              fazer: por meio do e-mail <span className="semiBold noCursor">contato@splitrisk.com.br</span>,
              <span>
                {' '}
                <a href="https://www.instagram.com/splitrisk.app/" target="blank" className="link">
                  Instagram{' '}
                </a>
              </span>
              ou pelo
              <span>
                {' '}
                <a href="https://br.linkedin.com/company/split-risk" target="blank" className="link">
                  Linkedin
                </a>{' '}
              </span>
              .
            </Text>
            <Subtitle id="m" className="increaseMarginTop">
              13. Representantes de seguros
            </Subtitle>

            <table className="table">
              <thead>
                <td style={{ fontWeight: 'bold' }}>Razão social</td>
                <td style={{ fontWeight: 'bold' }}>Nome fantasia</td>
                <td style={{ fontWeight: 'bold' }}>CNPJ</td>
                <td style={{ fontWeight: 'bold' }}>Endereço da sede</td>
                <td style={{ fontWeight: 'bold' }}>Canais de atendimento</td>
              </thead>
              <tbody>
                <tr>
                  <td>WESTSEG ADMINISTRADORA DE SEGUROS LTDA</td>
                  <td>WestSeg Seguros</td>
                  <td>49.267.419/0001-01</td>
                  <td>R TENENTE EULALIO GUERRA, 846, SALA 02. CUIABA/MT. CEP 78.045-065</td>
                  <td>(65) 3358-9000</td>
                </tr>
                <tr>
                  <td>LINCE TECNOLOGIA EM MONITORAMENTO LTDA</td>
                  <td>Lince Seguros</td>
                  <td>03.402.937/0001-81</td>
                  <td>R RODRIGUES ALVES, 31. CRICIUMA/SC. CEP 88.803-070</td>
                  <td>(48) 3045-4500</td>
                </tr>
                <tr>
                  <td>OCEANICA BRASIL OPERADORA DE SEGUROS E PARTIPACOES LTDA</td>
                  <td>Oceânica Seguros</td>
                  <td>50.184.794/0001-69</td>
                  <td>R COPAIBA LOTE, 01, SALA 416. BRASILIA/DF. CEP 71.919-540</td>
                  <td>(61) 9276-0443</td>
                </tr>
                <tr>
                  <td>MONT SEGUROS</td>
                  <td>Mont</td>
                  <td>29.950.829/0001-37</td>
                  <td>AV. LOURENCO CABREIRA, 452, JARDIM ANA LUCIA, SAO PAULO/SP, CEP 04.812-010</td>
                  <td>(11) 9526-9369</td>
                </tr>
                <tr>
                  <td>SINERGIA CWB PRESTACAO DE SERVICOS LTDA</td>
                  <td>Movim</td>
                  <td>30.769.906/0001-36</td>
                  <td>
                    RUA XV DE NOVEMBRO, 964, CONJUNTO 30, ANDAR 03, CONDOMINIO INTER WALTER SPRENGEL, CENTRO, CURITIBA-PR, CEP
                    80.060-00
                  </td>
                  <td>(41) 3333-5802</td>
                </tr>
                <tr>
                  <td>SEVEN 7 SERVIÇOS DIGITAIS INSTITUIÇÃO DE PAGAMENTO E INTERMEDIAÇÕES LTDA</td>
                  <td>Seven Seguros</td>
                  <td>36.851.667/0001-45</td>
                  <td>AV GABRIELA JUNQUEIRA DE FREITAS. UBERLANDIA/MG. CEP 38.414-126</td>
                  <td>(34) 9862-8972</td>
                </tr>
                <tr>
                  <td>STATUS GROUP LTDA</td>
                  <td>Status Seguros</td>
                  <td>50.206.052/0001-97</td>
                  <td>AVENIDA DOM AGUIRRE 4555, LOJA 5. SOROCABA/SP. CEP 18081-101</td>
                  <td>(31) 3624-4549</td>
                </tr>
                <tr>
                  <td>MYPASS DISTRIBUIDORA DE SEGUROS</td>
                  <td>MyPass Seguros</td>
                  <td>16.611.482/0001-56</td>
                  <td>R CARMELA DUTRA. JATAIZINHO/PR. CEP 86.210-000</td>
                  <td>(43) 9928-4560</td>
                </tr>
                <tr>
                  <td>FOX SEGUROS LTDA</td>
                  <td>Fox Seguros</td>
                  <td>50.829.165/0001-49</td>
                  <td>R GENTIL PORTUGAL DO BRASIL, 300. BELO HORIZONTE/MG. CEP 30.520-540</td>
                  <td>(31) 2559-0744</td>
                </tr>
                <tr>
                  <td>POTERE GESTAO DE NEGOCIOS LTDA</td>
                  <td>Potere Seguros</td>
                  <td>05.761.604/0001-74</td>
                  <td>AV 85, 556 - QUADRA 50 LOTE 1 SALA 1 EDIF ALVORADA ESQ R 137. GOIANIA/GO. CEP 74.160-010</td>
                  <td>(62) 3095-3619</td>
                </tr>
                <tr>
                  <td>TORRE SEGUROS LTDA</td>
                  <td>Torre Seguros</td>
                  <td>51.516.639/0001-65</td>
                  <td>AV CORONEL JOVE SOARES NOGUEIRA, 955. CONTAGEM/MG. CEP 32.260-470</td>
                  <td>(31) 9308-9523</td>
                </tr>
                <tr>
                  <td>SPS INSURTECH LTDA</td>
                  <td>Sem Perder Seguros</td>
                  <td>21.344.575/0001-00</td>
                  <td>AL JOAQUIM EUGENIO DE LIMA, 187. SAO PAULO/SP. CEP 01.403-001</td>
                  <td>(11) 8241-4969</td>
                </tr>
                <tr>
                  <td>M & L SERVICOS DE GESTAO EM DESENVOLVIMENTO PROFISSIONAL E GERENCIAL LTDA</td>
                  <td>Top Seguros</td>
                  <td>35.045.888/0001-63</td>
                  <td>AV MARECHAL CAMARA, 00160. RIO DE JANEIRO/RJ. CEP 20.020-907</td>
                  <td>(21) 6502-4977</td>
                </tr>
                <tr>
                  <td>STOPCLUB TECNOLOGIA, SOLUCOES E SERVICOS LTDA</td>
                  <td>STOPCLUB</td>
                  <td>29.316.027/0001-70</td>
                  <td>R DESEMBARGADOR ALFREDO RUSSEL, 00205. RIO DE JANEIRO/RJ. CEP 22.431-030</td>
                  <td>(21) 8136-4265</td>
                </tr>
                <tr>
                  <td>TURQUIM ADMINISTRADORA DE SEGUROS LTDA</td>
                  <td>TURQUIM SEGUROS</td>
                  <td>51.859.745/0001-41</td>
                  <td>AV SENADOR PINHEIRO MACHADO, 84. SANTOS/SP. CEP 11.065-605</td>
                  <td>(34) 3212-4645</td>
                </tr>
                <tr>
                  <td>G ALFA LTDA</td>
                  <td>Ax Seguros</td>
                  <td>34.821.940/0001-63</td>
                  <td>RUA SALDANHA MARINHA, Nº 945, SALA 02, BAIRRO MAURICIO NASSAU. CARUARU/PE. CEP 55012740</td>
                  <td>(81) 9910-6367</td>
                </tr>
                <tr>
                  <td>M2G CONSULTORIA E SERVICOS LTDA</td>
                  <td>Hemisfério</td>
                  <td>35.157.071/0001-87</td>
                  <td>AV. GENERAL ATAIDE TEIVE, Nº 1.381, LOJA B, BAIRRO MECEJANA, BOA VISTA – RR, CEP: 69.304-36</td>
                  <td>(95) 3224-2249</td>
                </tr>
                <tr>
                  <td>WATCH SPACE LTDA</td>
                  <td>Iclube Seguro Auto</td>
                  <td>47.289.416/0001-35</td>
                  <td>AV. PLACIDO MOTTIN, Nº 1766, LOJA 2, BAIRRO CECILIA, VIAMAO - RS, CEP: 94.475-50</td>
                  <td>(51) 8185-6179</td>
                </tr>
                <tr>
                  <td>MEO SEGURO LTDA</td>
                  <td>Meo Seguro</td>
                  <td>53.179.454/0001-38</td>
                  <td>
                    RUA RIO GRANDE DO NORTE, Nº 1.435, SALA 708, PAVIMENTO 7, BAIRRO SAVASSI, BELO HORIZONTE – MG, CEP: 30.130-13
                  </td>
                  <td>(34) 3212-4645</td>
                </tr>
                <tr>
                  <td>NELH INTERMEDIACAO DE NEGOCIOS LTDA</td>
                  <td>On Seguros</td>
                  <td>47.306.988/0001-85</td>
                  <td>Q QNC 9, S/N, LOJA 06, LOTE 02, BAIRRO TAGUATINGA NORTE, BRASÍLIA – DF, CEP: 72.115-59</td>
                  <td>(34) 9134-3752</td>
                </tr>
              </tbody>
            </table>
          </Line2>
        </Header>
        <Footer>
          <Line3>
            <div>
              <Simbolo
                src={simbolo}
                onClick={() => {
                  history.push('/');
                }}
              />
              <p>
                Split Risk seguradora. <br />
                CNPJ: 43.505.273/0001-09
              </p>
            </div>
            <div>
              <h5
                onClick={() => {
                  history.push('/termos');
                }}
              >
                Termos de uso e políticas de privacidade
              </h5>
              <div>
                <a href="https://www.instagram.com/splitrisk.app/" target="blank">
                  <RiInstagramFill size={20} />
                </a>
                <a href="https://br.linkedin.com/company/split-risk" target="blank">
                  <RiLinkedinBoxFill size={20} />
                </a>
              </div>
            </div>
          </Line3>
        </Footer>
      </Container>
      <LoginModal modalIsOpen={modalLoginIsOpen} handleCloseModal={handleCloseModalLogin} />
    </>
  );
}

export default Index;
