import styled from 'styled-components';
import img from '../../assets/background-site2.jpg';
import InputMask from 'react-input-mask';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  //position: absolute;
  background: #2b004f;
  color: #fff;
  font-family: 'Nunito', sans-serif;
`;

export const TopBar = styled.div`
  width: 100%;
  height: 15vh;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #19002a;

  @media (max-width: 700px) {
    height: 10vh;
  }
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;

  @media (max-width: 700px) {
    width: 80%;
  }
`;

export const Logo = styled.img`
  width: 150px;
  cursor: pointer;

  @media (max-width: 700px) {
    width: 110px;
  }
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0px 15px;
  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.9);
  }
`;

export const Login = styled.p`
  margin: 0px 5px;
  font-size: 14px;
  font-weight: 600;
`;

export const Header = styled.div`
  width: 100%;
  height: 85vh;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-image: url(${img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 700px) {
    height: 90vh;
    width: 100vh;
    justify-content: start;
  }
`;

export const Line2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;

  @media (max-width: 700px) {
    flex-direction: column;
    justify-content: space-evenly;
    height: 80%;
    width: 80%;
  }
`;

export const HeaderText = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  h1 {
    margin: none;
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 0;
  }
  h2 {
    margin: none;
    font-size: 28px;
    font-weight: 300;
  }

  .button-area {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 700px) {
    width: 100%;
    text-align: center;
    align-items: center;

    h1 {
      font-size: 35px;
      line-height: 30px;
      margin-bottom: 10px;
    }
    h2 {
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 30px;
    }
    .button-area {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const HeaderCotacao = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  @media (max-width: 700px) {
    width: 100%;
    align-items: center;
  }
`;

export const Form = styled.form`
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  padding: 30px;
  h2 {
    color: #19002a;
    font-size: 20px;
    margin-bottom: 30px;
    margin-top: 0;
    text-align: center;
  }
  div {
  }
  p {
    font-size: 12px;
    color: red;
    margin-top: -25px;
  }
  button {
    background-color: #00eb84;
    border: none;
    width: 100%;
    border-radius: 50px;
    padding: 10px;
    color: #19002a;
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    transition: filter 0.2s;
    outline: none;

    &:hover {
      filter: brightness(0.9);
    }

    @media (max-width: 700px) {
      width: 100%;
      align-items: center;
    }
  }
`;

export const Input = styled(InputMask)`
  height: 25px;
  width: 100%;
  font-size: 14px;
  font-family: 'Nunito', sans-serif;
  margin-bottom: 30px;
  outline: 0;
  border-width: 0 0 2px;
  border-color: #c9c9c9;
  &&::placeholder {
    color: gray;
  }
`;

export const Footer = styled.footer`
  background-color: #111111;
  height: 15vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  /* display: none; */

  @media (max-width: 700px) {
    height: auto;
    padding: 30px 0px;
  }
`;

export const Simbolo = styled.img`
  width: 35px;
  cursor: pointer;

  @media (max-width: 700px) {
    width: 25px;
  }
`;

export const Line3 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    div {
      margin-left: 40px;
    }
  }
  p {
    font-size: 10px;
    margin-left: 20px;
  }
  a {
    text-decoration: none;
    color: white;
    font-size: 12px;
    margin-left: 20px;
    transition: color 0.2s;
    &:hover {
      color: #00eb84;
    }
  }

  h5 {
    cursor: pointer;
    color: white;
    font-size: 12px;
    margin-left: 20px;
    transition: color 0.2s;
    font-weight: normal;
    &:hover {
      color: #00eb84;
    }
  }

  .info {
    display: flex;
    flex-direction: row;
  }

  .social {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 700px) {
    width: 80%;
    flex-direction: column;
    div {
      width: 100%;
      justify-content: center;
      flex-direction: column;
      /* margin-top: 20px; */

      div {
        margin: 0;
        width: 100%;
      }

      h5 {
        margin: 5px;
        color: #00eb84;
      }

      .info {
        margin-bottom: 40px;
      }
      .social {
        margin-top: 20px;
      }
    }
  }
`;
