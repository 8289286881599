import styled from 'styled-components';

export const AddressDataContainer = styled.div`
  display: flex;
  gap: 40px;

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const AddressDataInputContainer = styled.div`
  margin: 0;
  padding: 0;
`;
