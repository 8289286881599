import { success, error, warn, middleGray } from 'styles/colorProvider';
import { BsCircle, BsCircleHalf, BsCircleFill, BsShieldCheck, BsShieldX, BsChatSquareText } from 'react-icons/bs';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { FiDollarSign } from 'react-icons/fi';
import { BiCheck } from 'react-icons/bi';

export const formatPolicyStatus = (status) => {
  if (!status) return;
  if (status === 'awaiting_payment') return { label: 'Aguardando pagamento', color: warn, condition: 'Aguardando pagamento' };
  if (status === 'awaiting_pix') return { label: 'Aguardando pagamento', color: warn, condition: 'Aguardando pagamento' };
  if (status === 'awaiting_creditcard') return { label: 'Aguardando pagamento', color: warn, condition: 'Aguardando pagamento' };
  if (status === 'awaiting_debitcard') return { label: 'Aguardando pagamento', color: warn, condition: 'Aguardando pagamento' };
  if (status === 'awaiting_billet') return { label: 'Aguardando pagamento', color: warn, condition: 'Aguardando pagamento' };
  if (status === 'active') return { label: 'Ativo', color: success, condition: 'Seguro' };
  if (status === 'canceled') return { label: 'Cancelado', color: error, condition: 'Cancelada' };
  if (status === 'overdue') return { label: 'Atrasado', color: error, condition: 'Não seguro' };
};

export const formatProposalStatus = (status) => {
  if (!status) return;
  if (status === 'awaiting_registration')
    return {
      label: 'Aguardando cadastro',
      color: warn,
      condition: 'Aguardando cadastro',
      icon: <BsCircleHalf size={10} />,
      owner: 'Backoffice',
    };
  if (status === 'awaiting_inspection')
    return {
      label: 'Aguardando vistoria',
      color: middleGray,
      condition: 'Aguardando vistoria',
      icon: <BsCircle size={10} />,
      owner: 'Vistoriador',
    };
  if (status === 'awaiting_payment')
    return {
      label: 'Aguardando pagamento',
      color: middleGray,
      condition: 'Aguardando pagamento',
      icon: <BsCircleFill size={10} />,
      owner: 'Cliente',
    };

  if (status === 'activated') return { label: 'Ativada', color: warn, condition: 'Ativada' };
  if (status === 'rejected') return { label: 'Rejeitada', color: error, condition: 'Rejeitada' };
  if (status === 'cancelled') return { label: 'Cancelada', color: error, condition: 'Cancelada' };
};

export const formatHistoricStatus = (status) => {
  if (!status) return;
  if (status === 'new_proposal') return { icon: <IoDocumentTextOutline size={16} />, color: success };
  if (status === 'inspection') return { icon: <BsShieldX size={16} />, color: success };
  if (status === 'registration') return { icon: <BiCheck size={16} />, color: success };
  if (status === 'new_policy') return { icon: <BsShieldCheck size={16} />, color: success };
  if (status === 'attendance') return { icon: <BsChatSquareText size={16} />, color: warn };
  if (status === 'new_charge') return { icon: <FiDollarSign size={16} />, color: success };
  if (status === 'charge_paid') return { icon: <FiDollarSign size={16} />, color: success };
  if (status === 'charge_failed') return { icon: <FiDollarSign size={16} />, color: error };
  if (status === 'new_cancelation') return { icon: <BsShieldX size={16} />, color: warn };
  if (status === 'cancelation_updated') return { icon: <BsShieldX size={16} />, color: warn };
  if (status === 'new_complaint') return { icon: <BsChatSquareText size={16} />, color: success };
  if (status === 'complaint_updated') return { icon: <BsChatSquareText size={16} />, color: warn };
  if (status === 'complaint_finished') return { icon: <BsChatSquareText size={16} />, color: success };
};
