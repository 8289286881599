import styled from 'styled-components';
import img from '../../assets/background-site2.jpg';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  background: #2b004f;
  color: #fff;
  font-family: 'Nunito', sans-serif;
  background-image: url(${img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const Form = styled.form`
  width: 50%;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px;
  border-radius: 6px;
  color: #2b004f;
  max-height: 90%;
  overflow-y: auto;

  .logo {
    margin-bottom: 30px;
    height: 25px;
  }

  p {
    margin: 0;
    text-align: justify;
  }

  @media (max-width: 1000px) {
    width: 90%;
  }
`;
