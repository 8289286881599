import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  /* height: 100%;
  max-height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  //position: absolute;
  background: #2b004f;
  color: #fff;
  font-family: "Nunito", sans-serif;
`;

export const TopBar = styled.div`
  width: 100%;
  height: 15vh;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #19002a;
  
  @media (max-width: 700px) {
    height: 10vh;
  }
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  
  @media (max-width: 700px) {
    width: 80%;
  }
`;

export const Logo = styled.img`
  width: 150px;
  cursor: pointer;

  @media (max-width: 700px) {
    width: 110px;
  }
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  cursor: pointer;
    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.9);
    }
`;

export const Login = styled.p`
  margin-right: 5px;
  font-size: 14px;
  font-weight: 600;
`;

export const Header = styled.div`
  width: 100%;
  /* height: 85vh;
  max-height: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;

  span{
    font-weight: bold;
    color: #19002a;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #19002a
  }

  .semiBold{
    font-weight: bold;
    text-decoration: none;
    color: #000000;
  }

  @media (max-width: 700px) {
    justify-content: start;
  }
`;

export const Line2 = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: left;
  width: 70%;
  margin-bottom: 15vh;

  .textTab1{
    margin-left: 20px;
  }

  .textTab2{
    margin-left: 40px;
  }

  .decreaseMarginTop{
    margin-top: 0px;
  }

  .decreaseMarginBottom{
    margin-bottom: 0px;
  }

  .increaseMarginTop{
    margin-top: 40px;
  }

  .link{
    font-weight: bold;
    color: #19002a;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #19002a
  }

  .noCursor{
    cursor: text;
  }

  .table{
    width: 95%;
    color: black;
    margin-left: 20px;
    margin-top: 2.5%;
    margin-bottom: 2%;

    .table-title{
    background: #2b004f;	
    color: #fff;	
    font-weight: bolder;
    }

    td{ 
    border: 1px solid #2b004f;
    border-radius: 3px;
    padding: 0px 40px 18px;
    text-align:center;
    margin-top: 2px;
    
    }

    th{
    padding: 15px 40px;
    }
    @media (max-width: 700px) {
      display: none;
    }
  }
  @media (max-width: 700px) {
    flex-direction: column;
    justify-content: space-evenly;
    height: 80%;
    width: 80%;
  }
`;

export const Title = styled.h2`
  font-size: 28px;
  font-weight: 800;
  color: #19002a;
  align-items: left;
  margin-top: 50px;
`;

export const Text = styled.p`
  font-size: 16px;
  color: #000000;
  align-items: left;
  line-height: 22px;
`;

export const Subtitle = styled.h3`
  font-size: 18px;
  color: #19002a;
  align-items: left;
  line-height: 22px;
  font-weight: bold;
  margin-bottom: -10px;
`;

export const Item1 = styled(Subtitle)`
  font-size: 16px;
  margin-left: 50px;
`;

export const Footer = styled.footer`
  background-color: #111111;
  height: 15vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  /* display: none; */

  @media (max-width: 700px) {
    height: 20vh;
  }
`

export const Simbolo = styled.img`
  width: 35px;
  cursor: pointer;
  
  @media (max-width: 700px) {
    width: 25px;
  }
`

export const Line3 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    div{
      margin-left: 40px;
    }
  }
  p{
    font-size: 10px;
    margin-left: 20px;
  }
  a{
    text-decoration: none;
    color: white;
    font-size: 12px;
    margin-left: 20px;
    transition: color 0.2s;
    &:hover {
      color:#00eb84;
    }
  }

  h5{
    cursor: pointer;
    color: white;
    font-size: 12px;
    margin-left: 20px;
    transition: color 0.2s;
    font-weight: normal;
    &:hover {
      color:#00eb84;
    }
  }

  @media (max-width: 700px) {
    width: 80%;
    flex-direction: column-reverse;
    div{
      width: 100%;
      justify-content: center;
      /* margin-top: 20px; */

      div{
        margin:0;
        width:100%;
      }
    }
  }
`;

