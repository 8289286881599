/* eslint-disable react-hooks/exhaustive-deps */
import ReactLoading from 'react-loading';
import api from 'api';
import { useEffect, useState } from 'react';
import { MdDone } from 'react-icons/md';
import { Button } from 'components';
import { Container, ProductItem, CheckIconContaner, ProductsArea, SectionTitle } from '../../style';

const VehicleUseStep = ({ quotationData, setQuotationData, nextStep }) => {
  const [vehicleUses, setVehicleUses] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    setLoading(true);
    try {
      const resp = await api({
        method: 'GET',
        url: `/list-vehicle-uses`,
        headers: {
          apiKey: 'a3607db1-530f-4565-93a1-7c2ff693c8db',
          apiSecret: 'f2b85508-8097-4adf-990a-6ba37b246fc1',
        },
        params: {
          type: quotationData?.vehicle?.type,
        },
      });
      setVehicleUses(resp?.data);
      setLoading(false);
    } catch (error) {
      console.log('loadDataError', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container>
      <SectionTitle>Selecione o tipo de uso do veículo</SectionTitle>
      {loading && <ReactLoading color="rgb(0 176 90)" height={24} width={24} type="spin" />}
      <ProductsArea>
        {!loading &&
          vehicleUses?.map((vehicleUse) => {
            const isSelecedUse = vehicleUse.id === quotationData?.vehicle?.id_vehicle_use;
            return (
              <ProductItem
                selected={isSelecedUse}
                onClick={(e) => {
                  e.preventDefault();
                  setQuotationData({ ...quotationData, vehicle: { ...quotationData.vehicle, id_vehicle_use: vehicleUse?.id } });
                }}
                key={vehicleUse.id}
              >
                <CheckIconContaner selected={isSelecedUse}>
                  <MdDone size="20" color="rgb(0 176 90)" />
                </CheckIconContaner>
                {vehicleUse?.name}
              </ProductItem>
            );
          })}
      </ProductsArea>
      {quotationData?.vehicle?.id_vehicle_use && (
        <Button
          buttonColor="#fe9501"
          buttonBoxShadowColor="#fe9501"
          style={{ marginLeft: 0 }}
          height="35px"
          onClick={() => {
            nextStep();
          }}
        >
          Continuar
        </Button>
      )}
    </Container>
  );
};

export default VehicleUseStep;
