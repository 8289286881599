import styled from 'styled-components';

import { primary,secondary, middleGray, lightGray, error } from 'styles/colorProvider';

export const NewQuotationContainer = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 100vh;
`;

export const Centralizer = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
`;

export const Title = styled.h1`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
  color: ${primary};
  margin-bottom: 20px;
  width: 100%;

  @media (max-width: 1000px) {
    margin: 20px 0px;
  }
`;

export const StepContainer = styled.div`
  flex: 1 1 100%;
  display: flex;
  position: relative;
  width: 100%;
  min-height: 300px;
  main {
    flex: 1 1 auto;
  }
  aside {
    flex: 0 1 300px;
  }
`;

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 50px;
`;

export const SectionTitle = styled.h1`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
  color: ${(props) => (props.active ? primary : middleGray)};
  cursor: ${(props) => props.clickable && 'pointer'};
  margin: 10px 0px;
  margin-top: 20px;

  @media (max-width: 1000px) {
    margin: 20px 0px;
  }
`;

export const ProductsArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
`;

export const ProductItem = styled.button`
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  font-size: 12px;
  text-align: center;
  gap: 15px;
  font-weight: bold;
  color: ${({ selected }) => {
    if (selected) {
      return 'white';
    } else {
      return 'rgb(141 172 201)';
    }
  }};
  background: ${({ selected }) => {
    if (selected) {
      return 'rgb(73 204 122)';
    } else {
      return 'transparent';
    }
  }};
  min-height: ${(props) => props.height || '60px'};
  width: 250px;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  border: ${({ selected }) => {
    if (selected) {
      return '2px solid white';
    } else {
      return 'none';
    }
  }};
  border-radius: 10px;
  margin: 10px 10px 0px 10px;
  padding: 10px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  box-shadow: ${({ disabled, selected }) => {
    if (disabled) {
      return '3px 3px 5px 0px rgba(100, 100, 111, 0.2)';
    }
    if (selected) {
      return '0px 0px 5px 3px rgba(73, 204, 122, 0.5)';
    } else {
      return '3px 3px 5px 3px rgba(100, 100, 111, 0.2)';
    }
  }};

  &&:hover {
    box-shadow: ${({ disabled, selected }) => {
      if (disabled) {
        return '3px 3px 5px 0px rgba(100, 100, 111, 0.2)';
      }
      if (selected) {
        return '0px 0px 8px 3px rgba(73, 204, 122, 0.8)';
      } else {
        return '3px 3px 8px 3px rgba(100, 100, 111, 0.8)';
      }
    }};
  }

  @media (max-width: 1000px) {
    width: 80%;
  }
`;

export const CheckIconContaner = styled.div`
  flex: 0 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 100%;
  box-shadow: ${({ selected }) => {
    if (selected) {
      return '1px 1px 3px 0 gray inset';
    }
  }};

  /* box-shadow: 1px 1px 3px 0 gray inset; */
  background: #e3eef6;
  svg {
    opacity: ${({ selected }) => (selected ? '1' : '0')};
    transition: 0.3s;
  }
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e9f2fa;
  border-radius: 10px;
  box-shadow: 3px 3px 6px 1px rgb(95 157 231 / 28%);

  h4 {
    font-size: 24px;
    margin: 0px;
    color: #0cb863;
  }
  h5 {
    font-size: 14px;
    color: #0cb863;
    margin: 0;
  }
  p {
    font-size: 14px;
    font-weight: bold;
    color: #7c9fbf;
    margin: 0;
  }

  .value {
    margin: 0px;
    font-size: 24px;
    color: #fe9501;
    font-weight: bold;
  }

  .value-label {
    font-size: 15px;
    color: #121212;
    font-weight: normal;
  }

  .button-area {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
`;

export const ErrorMsg = styled.span`
  font-size: 12px;
  color: ${error};
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 50px;
  padding-bottom: 30px !important;

  hr {
    height: 1px;
    background-color: ${lightGray};
    width: 100%;
    border: none;
    margin-bottom: 20px;
  }

  label {
    font-size: 14px;
  }

  .empty {
    margin: 20px 0px;
    font-size: 14px;
    color: ${middleGray};
  }

  .editButton {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${middleGray};
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    &:hover {
      text-decoration: underline;
      opacity: 0.7;
    }
  }

  .newButton {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${middleGray};
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    margin-left: 30px;
    &:hover {
      text-decoration: underline;
      opacity: 0.7;
    }
    @media (max-width: 1000px) {
      margin-left: 30px;
      margin: 0px;
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }

  .link {
    color: ${middleGray};
    text-decoration: underline;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }

  .proposalInfo {
    margin: 5px 0px;
    font-size: 14px;
    color: ${middleGray};
  }

  .proposalValue {
    font-weight: bold;
    font-size: 16px;
    color: ${primary};
  }

  .bottomButtonArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 40%;
    padding-top: 30px;
    @media (max-width: 1000px) {
      padding-top: 20px;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const SuccessArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    color: ${primary};
    font-size: 20px;
  }

  p {
    margin: 0px;
  }

  .buttonArea {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
  }
`;
export const ContainerRadio = styled.div`
  /* background-color: red; */
  /* .border {
    border: 2px solid red;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 15px;
    top: -1px;
    position: relative;
  } */
  label {
    margin: 1rem;
  }
`;
export const Radio = styled.input`
  :after {
    cursor: pointer;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid ${primary};
  }

  :checked:after {
    transition-delay: 0.2s, 170ms;
    transition-property: background-color;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: ${secondary};
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid ${primary};
  }
  `;
  export const ContainerMainInputs = styled.div`
  display: flex;
  margin: 2rem 0;
`;
