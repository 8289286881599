import React, { useState } from 'react'
import Modal from 'react-modal'
import { FiX } from "react-icons/fi";
import api from "../../services/api";
import Cookies from 'js-cookie'
import ReactLoading from 'react-loading';
import { useHistory } from "react-router-dom";

import { useFormik } from 'formik';
import * as yup from 'yup';

import { Form, Input, Closed } from './styles'

function LoginModal({ modalIsOpen, handleCloseModal }) {
    const [loginIndisponivel, setLoginIndisponivel] = useState(false);
    const [errorMsg, setErrorMsg] = useState()
    const [loading, setLoading] = useState(false)
    const history = useHistory();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: yup.object({
            email: yup.string().email('Digite um e-mail válido').required('Digite o seu e-mail'),
            password: yup.string().required('Digite a sua senha')
        }),
        onSubmit: async (values, onSubmitProps) => {
            setLoginIndisponivel()
            setLoading(true)

            try {
                const response = await api.post('/login', { ...values })
                const { token } = response?.data
                Cookies.set('spltrsk-authtoken', token)
                setLoading(false)
                history.push('/dashboard')
            }
            catch (e) {
                setLoginIndisponivel(true);
                setErrorMsg(e?.response?.data?.error)
                setLoading(false)
            }

        },
    });

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={handleCloseModal}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <Closed>
                <div>
                    <FiX
                        color={'#111111'}
                        size={25}
                        onClick={handleCloseModal}
                    />
                </div>
            </Closed>

            <Form onSubmit={formik.handleSubmit}>
                <h2 >
                    Faça seu login
                </h2>

                {loginIndisponivel && (<p>{errorMsg}</p>)}

                <div>
                    <Input id="email" placeholder="E-mail" {...formik.getFieldProps('email')} />
                    {formik.touched.email && formik.errors.email ? (<p>{formik.errors.email}</p>) : null}
                </div>

                <div>
                    <Input id="password" type="password" placeholder="Senha" {...formik.getFieldProps('password')} className="obrigatorio" />
                    {formik.touched.password && formik.errors.password ? (<p>{formik.errors.password}</p>) : null}
                </div>

                <button type="submit" disabled={loading}>
                    {loading ? <ReactLoading type="spin" color="#19002a" height={20} width={20} /> : "Entrar"}
                </button>
            </Form>
        </Modal>
    )
}

export default LoginModal