import React from 'react';
import Routes from './routes';
import { GlobalStyle } from './styles/globalStyle';
import { ToastContainer } from 'react-toastify';
import './styles/fontProvider.css';
import 'react-toastify/dist/ReactToastify.css';

const App = () => (
  <div className="App">
    <>
      <GlobalStyle />
      <Routes />
      <ToastContainer />
    </>
  </div>
);

export default App;
