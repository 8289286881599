import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;

  .form {
    column-gap: 25px;
    row-gap: 15px;
  }
`;
