import ReactLoading from 'react-loading';
import api from 'api';
import styled from 'styled-components';
import { useState } from 'react';
import { Sidebar } from '../style';
import { Button } from 'components';
import { primary } from 'styles/colorProvider';
import { formatCurrency, formatZipCode } from 'react-data-formatter';
import { orderArray } from 'utils/array';
import { toast } from 'react-toastify';
import { API_TOKEN } from 'utils/token';

const AsideContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ mediaQuerieMaxWidth }) => mediaQuerieMaxWidth}) {
    flex: 1 1 auto;
  }
`;

export const SideBar = ({
  type,
  confirmButtonLabel = 'Enviar proposta',
  confirmButtonCollor = '',
  confirmButtonHandleClick = () => {},
  haveVehicleData = true,
  mediaQuerieMaxWidth = '824px',
  quotationData,
  quotationResponse,
  loadingQuotation,
  confirmButtonIsDisable = !quotationData?.products || quotationData?.products?.length < 1,
  proposalResponse,
}) => {
  const [loadingSave, setLoadingSave] = useState(false);

  const handleSaveQuotation = async (type) => {
    setLoadingSave(true);
    try {
      const response = await api({
        method: 'post',
        url: `/save-quotation`,
        data: {
          quotationRequest: quotationData,
          quotationResponse,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: API_TOKEN,
        },
      });
      setLoadingSave(false);

      if (type === 'print') {
        window.open(`/imprimir-cotacao/${response?.data?.id}`, '_blank');
      }

      toast.success('Cotação salva com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    } catch (e) {
      console.log({ quotationError: e });
      setLoadingSave(false);
    }
  };

  return (
    <AsideContainer mediaQuerieMaxWidth={mediaQuerieMaxWidth}>
      <Sidebar>
        {type === 'quotation' && (
          <>
            <h4>Dados do veículo</h4>
            <p>
              {quotationData?.vehicle?.brand} {quotationData?.vehicle?.model?.split(' ')[0]} {quotationData?.vehicle?.year_model}
            </p>
            <p>Placa: {quotationData?.vehicle?.plate?.toUpperCase() || 'Não emplacado'}</p>
            <p>Valor: {formatCurrency(quotationData?.vehicle?.value || 0)}</p>
            <p>Tipo de uso: {quotationResponse?.vehicleUse?.name}</p>
          </>
        )}

        {type === 'quotation' && (
          <>
            <h4 style={{ marginTop: 20 }}>Coberturas</h4>
            {orderArray(quotationData?.products, 'name_complete')?.map((i, index) => (
              <p key={index}>{i?.name_complete}</p>
            ))}
            {(!quotationData?.products || quotationData?.products?.length < 1) && (
              <p>
                <em>Nenhuma cobertura selecionada</em>
              </p>
            )}
          </>
        )}

        {type === 'proposal' && (
          <>
            <h4>Endereço</h4>
            <p>CEP: {formatZipCode(quotationData?.address?.zip_code)}</p>
            <p>
              Endereço: {quotationData?.address?.street}{' '}
              {quotationData?.address?.number && <>, {quotationData?.address?.number}</>}
            </p>
            {quotationData?.address?.complement && <p>Complemento: {quotationData?.address?.complement}</p>}
            <p>
              Cidade/UF: {quotationData?.address?.city} {quotationData?.address?.state && <>, {quotationData?.address?.state}</>}
            </p>
          </>
        )}

        {type === 'proposal' && (
          <>
            <h4 style={{ marginTop: 20 }}>Coberturas</h4>
            {orderArray(quotationData?.products, 'name_complete')?.map((i, index) => (
              <p key={index}>{i?.name_complete}</p>
            ))}
            {(!quotationData?.products || quotationData?.products?.length < 1) && (
              <p>
                <em>Nenhuma cobertura selecionada</em>
              </p>
            )}
          </>
        )}

        <>
          {loadingQuotation && (
            <>
              <h5 style={{ marginTop: 20 }}>Valor total</h5>
              <div style={{ width: '100%', boxSizing: 'border-box', padding: 20 }}>
                <ReactLoading color={primary} height={24} width={24} type="spin" />
              </div>
            </>
          )}
          {!loadingQuotation && quotationData?.products && quotationData?.products?.length > 1 && (
            <>
              <h5 style={{ marginTop: 20 }}>Valor total</h5>
              <p className="value">
                {formatCurrency(quotationResponse?.discounted_final_value || quotationResponse?.final_value || 0)}{' '}
                <span className="value-label">/ mês</span>
              </p>
              <p style={{ fontSize: 12 }}>IOF: {formatCurrency(quotationResponse?.iof || 0)}</p>
            </>
          )}
        </>

        <div className="button-area vertical-button-area">
          <Button
            disabled={loadingSave}
            height="35px"
            onClick={handleSaveQuotation}
            buttonColor={confirmButtonCollor}
            buttonBoxShadowColor="transparent"
            borderRadius="10px"
          >
            {loadingSave ? <ReactLoading color="white" height={24} width={24} type="spin" /> : 'Salvar cotação'}
          </Button>
          <Button
            disabled={loadingSave}
            height="35px"
            onClick={() => handleSaveQuotation('print')}
            buttonColor={confirmButtonCollor}
            buttonBoxShadowColor="transparent"
            borderRadius="10px"
          >
            {loadingSave ? <ReactLoading color="white" height={24} width={24} type="spin" /> : 'Imprimir cotação'}
          </Button>
          <Button
            disabled={proposalResponse?.response?.id || confirmButtonIsDisable}
            height="35px"
            onClick={confirmButtonHandleClick}
            buttonColor={confirmButtonCollor}
            buttonBoxShadowColor="transparent"
            borderRadius="10px"
          >
            {confirmButtonLabel}
          </Button>
        </div>
        {proposalResponse?.response?.id && <small style={{ fontSize: 10, textAlign: 'center' }}>Proposta já emitida</small>}
      </Sidebar>
    </AsideContainer>
  );
};

export default SideBar;
