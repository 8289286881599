import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MaskedInput from 'react-input-mask';
import NumberFormat from 'react-number-format';
import { success as SuccessColor, error as ErrorColor, lightGray } from 'styles/colorProvider';

import { BsFillCheckCircleFill, BsXCircleFill } from 'react-icons/bs';
import { StyledInput, Label, Container } from './styles';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { ImCreditCard } from 'react-icons/im';

function Select({
  icon,
  password,
  type,
  placeholder,
  label,
  mask,
  numeric,
  price,
  value,
  onChange,
  width,
  disabled,
  disableLabel,
  marginVertical,
  card,
  onFocus,
  height,
  endIcon,
  success,
  error,
  percentage,
  qustionLabel,
  style,
  children,
  ...props
}) {
  const [showPassword, setShowPassword] = useState(false);

  const inputType = showPassword ? 'text' : numeric ? 'tel' : type;

  return (
    <Container width={width} {...props}>
      {!disableLabel && <Label>{label}:</Label>}

      <StyledInput
        success={success}
        error={error}
        height={height}
        icon={icon && true}
        width={width}
        marginVertical={marginVertical}
        disabled={disabled}
      >
        {icon && <div>{icon}</div>}
        <select value={value} placeholder={placeholder} onFocus={onFocus} onChange={onChange} disabled={disabled}>
          {children}
        </select>
      </StyledInput>
    </Container>
  );
}

export default Select;
