/* eslint-disable react-hooks/exhaustive-deps */
import ReactLoading from 'react-loading';
import SideBar from './SideBar';
import api from 'api';
import { useEffect, useState } from 'react';
import { BsFillCheckCircleFill, BsCircle } from 'react-icons/bs';
import { orderCoveragesArray } from 'utils/array';
import { Container, SectionTitle } from '../../style';
import { Content, OptionsSide, VehicleDataSide, ProductItem, ProductItemButton, CoveragesArea } from './style';

export const CoverageStep = ({
  quotationData,
  setQuotationData,
  loadingQuotation,
  quotationResponse,
  nextStep,
  proposalResponse,
  handleQuotation,
}) => {
  const [allCoverages, setAllCoverages] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(quotationData?.products || []);
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    setLoading(true);
    try {
      const resp = await api({
        method: 'GET',
        url: `/list-coverages-agent`,
        headers: {
          apiKey: 'a3607db1-530f-4565-93a1-7c2ff693c8db',
          apiSecret: 'f2b85508-8097-4adf-990a-6ba37b246fc1',
        },
        params: {
          vehicleBrand: quotationData?.vehicle?.id_brand,
          type: quotationData?.vehicle?.type,
        },
      });
      setAllCoverages(resp?.data);
      setLoading(false);
    } catch (error) {
      console.log('loadDataError', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const groups = allCoverages?.map((i) => i.group);
  const uniqueGroups = groups?.filter((a, b) => groups?.findIndex((item) => item?.id === a?.id) === b);

  const toogleProduct = (product, unique) => {
    const list = selectedProducts;
    let finalList = list;

    const isInList = list.findIndex((i) => i.id === product.id) > -1;

    if (isInList) {
      const nList = list.filter((i) => i.id !== product.id);
      finalList = nList;
    } else {
      const nList = unique ? list.filter((i) => i.id_group !== product.id_group) : list;
      nList.push(product);
      finalList = nList;
    }

    setQuotationData({ ...quotationData, products: finalList });
    setSelectedProducts(finalList);
    handleQuotation(finalList);
  };

  const toogleProductGroup = (products) => {
    const list = selectedProducts;
    let finalList = list;

    for (const product of products) {
      const isInList = list.findIndex((i) => i.id === product.id) > -1;

      if (isInList) {
        const nList = finalList.filter((i) => i.id !== product.id);
        finalList = nList;
      } else {
        const nList = list;
        nList.push(product);
        finalList = nList;
      }
    }

    setQuotationData({ ...quotationData, products: finalList });
    setSelectedProducts(finalList);
    handleQuotation(finalList);
  };

  const CoverageItem = ({ group, coverage, info }) => {
    const groupVariants = allCoverages?.filter((i) => i?.id_group === group?.id);
    const isGrouped = groupVariants.every((i) => !i?.variation_name);
    const selected = selectedProducts.filter((item) => groupVariants.map((i) => i.id).indexOf(item.id) !== -1).length > 0;
    const hasVariations = groupVariants.every((i) => i?.variation_name);

    return (
      <ProductItem
        hasVariations={hasVariations}
        selected={selected}
        disabled={loadingQuotation}
        onClick={() =>
          !loadingQuotation && isGrouped && toogleProductGroup(allCoverages?.filter((i) => i?.id_group === group?.id))
        }
      >
        {!selected && <BsCircle size={24} style={{ marginRight: 10 }} />}
        {selected && <BsFillCheckCircleFill size={24} style={{ marginRight: 10 }} />}
        <div>
          {coverage?.group?.name} {info && <small style={{ fontWeight: 100, fontSize: 10 }}>{info}</small>}
          {hasVariations && (
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
              {orderCoveragesArray(groupVariants, 'variation_name')?.map((variant) => (
                <ProductItemButton
                  disabled={loadingQuotation}
                  onClick={() => toogleProduct(variant, true)}
                  isChecked={selectedProducts?.findIndex((listItem) => listItem.id === variant?.id) > -1}
                >
                  {variant?.variation_name}
                </ProductItemButton>
              ))}
            </div>
          )}
        </div>
      </ProductItem>
    );
  };

  return (
    <Container>
      <Content>
        {loading && <ReactLoading color="rgb(0 176 90)" height={24} width={24} type="spin" />}
        {!loading && (
          <OptionsSide>
            <SectionTitle>Selecione as coberturas</SectionTitle>
            <h3 style={{ marginTop: 35 }}>Coberturas básicas</h3>
            <CoveragesArea>
              {uniqueGroups
                ?.filter((i) => i?.category === 'basic')
                ?.map((group) => (
                  <div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {allCoverages
                        ?.filter((i) => i?.id_group === group?.id)
                        .filter(
                          (obj, index, self) =>
                            self.findIndex((t) => {
                              return t.id_group === obj.id_group;
                            }) === index
                        )
                        ?.map((coverage) => (
                          <CoverageItem group={group} coverage={coverage} />
                        ))}
                    </div>
                  </div>
                ))}
            </CoveragesArea>

            <h3 style={{ marginTop: 35 }}>Coberturas adicionais</h3>
            <CoveragesArea>
              {uniqueGroups
                ?.filter((i) => i?.category === 'aditional')
                ?.map((group) => (
                  <div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {allCoverages
                        ?.filter((i) => i?.id_group === group?.id)
                        .filter(
                          (obj, index, self) =>
                            self.findIndex((t) => {
                              return t.id_group === obj.id_group;
                            }) === index
                        )
                        ?.map((coverage) => (
                          <CoverageItem info={group.id === 8 && '(Padrão 10%)'} group={group} coverage={coverage} />
                        ))}
                    </div>
                  </div>
                ))}
            </CoveragesArea>
          </OptionsSide>
        )}

        {!loading && (
          <VehicleDataSide>
            <SideBar
              type="quotation"
              confirmButtonHandleClick={() => {
                nextStep();
              }}
              loadingQuotation={loadingQuotation}
              quotationData={quotationData}
              quotationResponse={quotationResponse}
              haveAddress={false}
              haveCoverages={false}
              confirmButtonCollor="#fe9501"
              confirmButtonBoxShadowColor="#ff9500"
              proposalResponse={proposalResponse}
            />
          </VehicleDataSide>
        )}
      </Content>
    </Container>
  );
};

export default CoverageStep;
