import React, { useState } from 'react';
import api from 'api';
import ReactLoading from 'react-loading';
import Lottie from 'react-lottie';

import successAnimation from 'assets/success-2.json';
import logo from 'assets/logo-default.png';
import { Button, Input } from 'components';
import { Container, Form } from './styles';
import { toast } from 'react-toastify';
import { FaLinkedin } from 'react-icons/fa';

function Index({ history }) {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      ...formData,
      important_item: formData?.important_item === 'other' ? formData.other : formData.important_item,
    };

    try {
      await api({
        method: 'POST',
        url: `/new-curriculum`,
        headers: {
          'Content-Type': 'application/json',
        },
        data,
        json: true,
      });
      setLoading(false);
      setFormData({});
      setStep(2);
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        {step === 1 && (
          <Form>
            <img src={logo} className="logo" alt="SplitRisk Logo" />

            <h3>Quer fazer parte do nosso time?</h3>
            <p>Fique de olho no nosso Linkedin. Todas as nossas vagas são compartilhadas por lá!</p>

            <Button
              style={{ margin: 0, marginTop: 30 }}
              onClick={() => window.open('https://www.linkedin.com/company/split-risk/')}
            >
              <FaLinkedin style={{ marginRight: 10 }} /> Linkedin
            </Button>
          </Form>
        )}
        {step === 2 && (
          <Form>
            <Lottie
              autoplay
              options={{
                loop: false,
                autoplay: true,
                animationData: successAnimation,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={170}
              width={170}
            />

            <h3 style={{ width: '100%', textAlign: 'center', margin: 0, marginTop: 20 }}>
              Agradecemos o interesse em trabalhar conosco.
            </h3>
            <h4 style={{ width: '100%', textAlign: 'center', margin: 0, marginTop: 5 }}>
              Suas informações foram enviadas com sucesso!
            </h4>
          </Form>
        )}
      </Container>
    </>
  );
}

export default Index;
