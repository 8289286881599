import React, { useState } from 'react';
import api from 'api';
import ReactLoading from 'react-loading';
import Lottie from 'react-lottie';

import successAnimation from 'assets/success-2.json';
import logo from 'assets/logo-default.png';
import { Button, Input, Select } from 'components';
import { Container, Form } from './styles';
import { toast } from 'react-toastify';

function Index({ history }) {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      ...formData,
      important_item: formData?.important_item === 'other' ? formData.other : formData.important_item,
    };

    try {
      await api({
        method: 'POST',
        url: `/partner-lead`,
        headers: {
          'Content-Type': 'application/json',
        },
        data,
        json: true,
      });
      setLoading(false);
      // toast.success('Sucesso! Obrigado por se cadastrar, em breve entraremos em contato!', {
      //   position: toast.POSITION.TOP_RIGHT,
      //   theme: 'colored',
      // });
      setFormData({});
      setStep(2);
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        {step === 1 && (
          <Form>
            <img src={logo} className="logo" alt="SplitRisk Logo" />

            <p style={{ marginBottom: 15, fontWeight: 'bold', fontSize: 22 }}>Prezado Corretor de Seguros,</p>
            <p style={{ marginBottom: 15 }}>
              Estamos iniciando nossa Operação para distribuição de Seguro Auto, e ficamos muito felizes com o seu interesse!{' '}
            </p>
            <p>
              Por favor, preencha o formulário abaixo com os seus dados, e em breve entraremos em contato para sequência do
              processo de cadastro.
            </p>

            <div style={{ marginTop: 30 }}>
              <Input
                questionLabel
                value={formData?.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                width="100%"
                style={{ marginBottom: 10 }}
                label="Nome da empresa"
                type="text"
              />
              <Input
                questionLabel
                value={formData?.document}
                onChange={(e) => setFormData({ ...formData, document: e.target.value })}
                width="100%"
                style={{ marginBottom: 10 }}
                label="CNPJ"
                mask=" 99.999.999/9999-99"
              />
              <Input
                questionLabel
                value={formData?.city}
                onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                width="100%"
                style={{ marginBottom: 10 }}
                label="Cidade"
                type="text"
              />
              <Input
                questionLabel
                value={formData?.owner_name}
                onChange={(e) => setFormData({ ...formData, owner_name: e.target.value })}
                width="100%"
                style={{ marginBottom: 10 }}
                label="Nome da pessoa de contato"
                type="text"
              />
              <Input
                questionLabel
                value={formData?.owner_phone}
                onChange={(e) => setFormData({ ...formData, owner_phone: e.target.value })}
                width="100%"
                style={{ marginBottom: 10 }}
                label="WhatsApp"
                mask="(99) 99999-9999"
              />
              <Input
                questionLabel
                value={formData?.owner_mail}
                onChange={(e) => setFormData({ ...formData, owner_mail: e.target.value })}
                width="100%"
                style={{ marginBottom: 10 }}
                label="E-mail"
                type="text"
              />
              <Select
                questionLabel
                value={formData?.policies_amount}
                onChange={(e) => setFormData({ ...formData, policies_amount: e.target.value })}
                width="100%"
                style={{ marginBottom: 10 }}
                label="Quantas apólices de seguro auto sua empresa comercializa por mês?"
                type="text"
              >
                <option>Selecione</option>
                <option value="0 a 5">0 a 5</option>
                <option value="6 a 10">6 a 10</option>
                <option value="11 a 20">11 a 20</option>
                <option value="Mais de 20">Mais de 20</option>
              </Select>
              <Input
                questionLabel
                value={formData?.branch}
                onChange={(e) => setFormData({ ...formData, branch: e.target.value })}
                width="100%"
                style={{ marginBottom: 10 }}
                label="Além de seguro auto, em quais outros ramos você atua?"
                type="text"
              />
              <Input
                questionLabel
                value={formData?.how_find}
                onChange={(e) => setFormData({ ...formData, how_find: e.target.value })}
                width="100%"
                style={{ marginBottom: 10 }}
                label="Como conheceu a Split Risk?"
                type="text"
              />
              <Select
                questionLabel
                value={formData?.important_item}
                onChange={(e) => setFormData({ ...formData, important_item: e.target.value })}
                width="100%"
                style={{ marginBottom: 10 }}
                label="Qual dos items abaixo você vê maior importância na relação seguradora e corretor?"
                type="text"
              >
                <option>Selecione</option>
                <option value="Produtos mais atraentes / flexíveis">Produtos mais atraentes / flexíveis</option>
                <option value="Produtos mais baratos">Produtos mais baratos</option>
                <option value="Melhor comissionamento">Melhor comissionamento</option>
                <option value="Melhor ferramenta de cotação / gestão de carteira">
                  Melhor ferramenta de cotação / gestão de carteira
                </option>
                <option value="other">Outra</option>
              </Select>

              {formData?.important_item === 'other' && (
                <Input
                  questionLabel
                  value={formData?.other}
                  onChange={(e) => setFormData({ ...formData, other: e.target.value })}
                  width="100%"
                  style={{ marginBottom: 10 }}
                  label="Qual?"
                  type="text"
                />
              )}
              <Button
                onClick={handleSubmit}
                style={{ width: 300, height: 35, margin: 0, fontSize: 18, marginTop: 20 }}
                disabled={
                  loading ||
                  !formData.name ||
                  !formData.document ||
                  !formData.city ||
                  !formData.owner_name ||
                  !formData.owner_phone ||
                  !formData.owner_mail ||
                  !formData.policies_amount ||
                  !formData.branch ||
                  !formData.how_find ||
                  !formData.important_item
                }
              >
                {loading ? <ReactLoading color="#fff" height={30} width={30} type="spin" /> : 'Enviar'}
              </Button>
            </div>
          </Form>
        )}
        {step === 2 && (
          <Form>
            <Lottie
              autoplay
              options={{
                loop: false,
                autoplay: true,
                animationData: successAnimation,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={170}
              width={170}
            />

            <h3 style={{ width: '100%', textAlign: 'center', margin: 0, marginTop: 20 }}>
              Agradecemos o interesse em se tornar nosso parceiro.
            </h3>
            <h4 style={{ width: '100%', textAlign: 'center', margin: 0, marginTop: 5 }}>
              Suas informações foram enviadas com sucesso!
            </h4>
            <p style={{ width: '100%', textAlign: 'center', margin: 0, marginTop: 20, color: '#868686' }}>
              Em breve o nosso time entrará em contato para a sequência do cadastramento.
            </p>
            <p style={{ width: '100%', textAlign: 'center', margin: 0, marginTop: 30, fontWeight: 'bold' }}>Equipe Split Risk</p>
          </Form>
        )}
      </Container>
    </>
  );
}

export default Index;
