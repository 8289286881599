import { formatCurrency } from 'react-data-formatter';
import { CommissionSliderContainer, CommissionValue, SliderContent } from './style';
import Cookies from 'js-cookie';
import { API_TOKEN } from 'utils/token';
const host = window.location.hostname;

const marks = [
  {
    value: 10,
    label: '10%',
  },
  {
    value: 33,
    label: '33%',
  },
];

const ComissionSlider = ({ quotationResponse, quotationData, setQuotationData, handleQuotation }) => {
  const permissions = API_TOKEN;

  const hasPermission = (menu) => {
    const menuIndex = permissions.findIndex((i) => i.feature === menu);
    if (menuIndex === -1) return false;
    return permissions[menuIndex].hasPermission;
  };
  return (
    <CommissionSliderContainer>
      {/* {(host.includes('corretor') || host.includes('localhost')) && (
        <SliderContent
          track={false}
          aria-labelledby="track-false-slider"
          valueLabelDisplay="auto"
          valueLabelFormat={(e) => {
            return `${Math.round(e)}%`;
          }}
          getAriaValueText={(value) => `${value}`}
          defaultValue={(quotationData?.comission || 0) * 100}
          value={quotationData?.comission * 100}
          onChange={(event) => {
            setQuotationData({ ...quotationData, comission: event.target.value / 100 });
          }}
          onChangeCommitted={handleQuotation}
          min={10}
          max={33}
          step={1}
          marks={marks}
        />
      )} */}
    </CommissionSliderContainer>
  );
};

export default ComissionSlider;
