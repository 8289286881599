/* eslint-disable react-hooks/exhaustive-deps */
import { Input } from 'components';
import { Container, FilesContainer } from './style';
import api from 'api/index';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { UploadFileInput } from 'components/index';
import { fileTypes } from 'utils/fileTypes';
import { useState, useEffect } from 'react';
import { Select } from 'components';
import { FaMotorcycle } from 'react-icons/fa';
import { RiCarFill } from 'react-icons/ri';
import { error, success } from 'styles/colorProvider';
import { API_TOKEN } from 'utils/token';

const VehicleData = ({ quotationData, setQuotationData }) => {
  const [colorsList, setColorsList] = useState([]);
  const selectedColor = colorsList.find((currentColor) => currentColor.id === Number(quotationData?.vehicle?.id_color));

  const getVehicle = async () => {
    try {
      const vehicle = await api({
        method: 'get',
        url: `/quotation/vehicle/${quotationData.quotationProposalData.vehicleId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: API_TOKEN,
        },
      });
      const vehicleData = vehicle.data;
      setQuotationData((prev) => ({
        ...prev,
        vehicle: {
          ...prev.vehicle,
          brand: vehicleData.TB_CONSTANT_VEHICLE_BRANDS.name,
          model: vehicleData.TB_CONSTANT_VEHICLE_MODELS.name,
          vehicle_type: vehicleData.TB_CONSTANT_VEHICLE_TYPES.name,
          color: vehicleData.TB_CONSTANT_VEHICLE_COLOR.name,
          vehicle_use: vehicleData.TB_CONSTANT_VEHICLE_USES.name,
        },
      }));
    } catch (error) {
      const title = error?.response?.data?.error || 'Erro ao buscar veiculo';
      return toast.error(title, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const loadColors = async () => {
    const response = await api.get(`/list-vehicle-colors`, {
      headers: {
        apiKey: 'a3607db1-530f-4565-93a1-7c2ff693c8db',
        apiSecret: 'f2b85508-8097-4adf-990a-6ba37b246fc1',
      },
    });
    setColorsList(response.data);
  };

  useEffect(() => {
    getVehicle();
  }, []);

  const setInvoiceFile = (invoiceFile) => setQuotationData((prev) => ({ ...prev, vehicleInvoice: invoiceFile }));
  const setDocumentFile = (documentFile) => setQuotationData((prev) => ({ ...prev, vehicleDocument: documentFile }));

  const removeInvoiceFile = () => setQuotationData((prev) => ({ ...prev, vehicleInvoice: null }));
  const removeDocumentFile = () => setQuotationData((prev) => ({ ...prev, vehicleDocument: null }));

  useEffect(() => {
    loadColors();
  }, []);

  return (
    <Container>
      <div className="proposal-section" style={{ marginTop: 15 }}>
        <h3>Dados do veículo</h3>
        <div className="form">
          <Input
            label="Placa"
            height="30px"
            width="230px"
            style={{ marginRight: 50, marginTop: 20 }}
            value={quotationData?.vehicle?.plate}
            disabled
          />
          <Input
            label="Chassi"
            height="30px"
            width="230px"
            mask="*****************"
            style={{ marginRight: 50, marginTop: 20 }}
            value={quotationData?.vehicle?.chassi}
            onChange={(event) =>
              setQuotationData({ ...quotationData, vehicle: { ...quotationData?.vehicle, chassi: event.target.value } })
            }
            success={quotationData?.vehicle?.chassi?.length === 17}
            error={quotationData?.vehicle?.chassi?.length !== 17}
          />
          <Input
            label="Renavam"
            mask="***********"
            height="30px"
            width="230px"
            style={{ marginRight: 50, marginTop: 20 }}
            value={quotationData?.vehicle?.renavam}
            onChange={(event) =>
              setQuotationData({ ...quotationData, vehicle: { ...quotationData?.vehicle, renavam: event.target.value } })
            }
            success={quotationData?.vehicle?.renavam?.length === 11}
            error={quotationData?.vehicle?.renavam?.length !== 11}
          />
          <Input
            label="Marca"
            height="30px"
            width="230px"
            style={{ marginRight: 50, marginTop: 20 }}
            value={quotationData?.vehicle?.brand}
            disabled
          />
          <Input
            label="Modelo"
            height="30px"
            width="230px"
            style={{ marginRight: 50, marginTop: 20 }}
            value={quotationData?.vehicle?.model}
            disabled
          />
          <Input
            label="Tipo"
            height="30px"
            width="230px"
            style={{ marginRight: 50, marginTop: 20 }}
            value={quotationData?.vehicle?.vehicle_type}
            disabled
          />
          <Input
            label="Ano fabricação"
            height="30px"
            width="230px"
            style={{ marginRight: 50, marginTop: 20 }}
            value={quotationData?.vehicle?.build_year}
            disabled
          />
          <Input
            label="Ano modelo"
            height="30px"
            width="230px"
            style={{ marginRight: 50, marginTop: 20 }}
            value={quotationData?.vehicle?.year_model}
            disabled
          />
          <Input
            label="Veículo de leilão"
            height="30px"
            width="230px"
            style={{ marginRight: 50, marginTop: 20 }}
            value={quotationData?.vehicle?.auction ? 'Sim' : 'Não'}
            disabled
          />
          <Input
            label="Veículo 0km"
            height="30px"
            width="230px"
            style={{ marginRight: 50, marginTop: 20 }}
            value={quotationData?.vehicle?.zero_km ? 'Sim' : 'Não'}
            disabled
          />
          <Input
            label="Gás Natural Veicular (GNV)"
            height="30px"
            width="230px"
            style={{ marginRight: 50, marginTop: 20 }}
            value={quotationData?.vehicle?.gnv ? 'Sim' : 'Não'}
            disabled
          />

          {/* VEÍCULO POSSUI SEGURO */}
          <div name="vehicle-insured-field" style={{ marginRight: 50, marginTop: 20 }}>
            <p style={{ fontSize: 12 }}>Veículo Possui Seguro:</p>
            <div
              className="input-radio"
              style={{
                borderColor:
                  quotationData?.vehicle?.vehicle_already_insured === null ||
                  quotationData?.vehicle?.vehicle_already_insured === undefined
                    ? error
                    : success,
              }}
            >
              <input
                id="vehicleInsuredYes"
                type="radio"
                checked={quotationData?.vehicle?.vehicle_already_insured === true}
                onChange={() =>
                  setQuotationData({ ...quotationData, vehicle: { ...quotationData.vehicle, vehicle_already_insured: true } })
                }
              />
              <label htmlFor="vehicleInsuredYes">Sim</label>

              <input
                style={{ marginLeft: 20 }}
                id="vehicleInsuredNo"
                type="radio"
                checked={quotationData?.vehicle?.vehicle_already_insured === false}
                onChange={() =>
                  setQuotationData({ ...quotationData, vehicle: { ...quotationData.vehicle, vehicle_already_insured: false } })
                }
              />
              <label htmlFor="vehicleInsuredNo">Não</label>
            </div>
          </div>

          {/* 18 - 25 ANOS */}
          <div name="drivers-age-field" style={{ marginRight: 40, marginTop: 20 }}>
            <p style={{ fontSize: 12 }}>Condutores entre 18 e 25 anos:</p>
            <div
              className="input-radio"
              style={{
                borderColor:
                  quotationData?.vehicle?.some_driver_18_25_years_old === null ||
                  quotationData?.vehicle?.some_driver_18_25_years_old === undefined
                    ? error
                    : success,
              }}
            >
              <input
                id="driversAgeYes"
                type="radio"
                checked={quotationData?.vehicle?.some_driver_18_25_years_old === true}
                onChange={() =>
                  setQuotationData({
                    ...quotationData,
                    vehicle: { ...quotationData.vehicle, some_driver_18_25_years_old: true },
                    quotationProposalData: {
                      ...quotationData.quotationProposalData,
                      under25: true,
                    },
                  })
                }
              />
              <label htmlFor="driversAgeYes">Sim</label>
              <input
                style={{ marginLeft: 20 }}
                id="driversAgeNo"
                type="radio"
                checked={quotationData?.vehicle?.some_driver_18_25_years_old === false}
                onChange={() =>
                  setQuotationData({
                    ...quotationData,
                    vehicle: { ...quotationData.vehicle, some_driver_18_25_years_old: false },
                    quotationProposalData: {
                      ...quotationData.quotationProposalData,
                      under25: false,
                    },
                  })
                }
              />
              <label htmlFor="driversAgeNo">Não</label>
            </div>
          </div>

          {/* COR DO VEÍCULO */}

          <Select
            label="Cor do veículo"
            style={{ marginRight: 50, marginTop: 20 }}
            icon={
              quotationData?.vehicle?.type === 7 ? (
                <FaMotorcycle color={selectedColor?.color} />
              ) : (
                <RiCarFill color={selectedColor?.color} />
              )
            }
            placeholder="Selecione a cor"
            value={quotationData?.vehicle?.id_color}
            onChange={(e) => {
              const foundColor = colorsList.find((c) => c.id === Number(e.target.value));
              setQuotationData({
                ...quotationData,
                vehicle: {
                  ...quotationData.vehicle,
                  color: foundColor?.name,
                  id_color: Number(e.target.value),
                  vehicle_color: colorsList[colorsList?.findIndex((i) => i.id === Number(e.target.value))],
                },
              });
            }}
            onload={(event) => event?.target?.value}
            error={!quotationData?.vehicle?.id_color}
            success={quotationData?.vehicle?.id_color}
          >
            <option value="">Selecione</option>
            {colorsList.map((color) => (
              <option value={color.id} key={`color-id-${color.id}`}>
                {color.name}
              </option>
            ))}
          </Select>

          <Input
            label="Uso do veículo"
            height="30px"
            width="230px"
            style={{ marginRight: 50, marginTop: 20 }}
            value={quotationData?.vehicle?.vehicle_use}
            disabled
          />

          {!!quotationData?.vehicle?.zero_km && (
            <FilesContainer>
              <UploadFileInput
                required
                label="Nota fiscal"
                text="Selecione a nota fiscal"
                onFileSelect={setInvoiceFile}
                onFileRemove={removeInvoiceFile}
                acceptedExtensions={[fileTypes.pdf, fileTypes.png, fileTypes.jpeg]}
              />
              <UploadFileInput
                label="Documento"
                text="Selecione o documento"
                onFileSelect={setDocumentFile}
                onFileRemove={removeDocumentFile}
                acceptedExtensions={[fileTypes.pdf, fileTypes.png, fileTypes.jpeg]}
              />
            </FilesContainer>
          )}
        </div>
      </div>
    </Container>
  );
};

export default VehicleData;
