import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const MobileLogo = styled.div`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  display: none;
  margin-bottom: 20px;
  @media (max-width: 1100px) {
    display: flex;
  }
`;

export const Sidebar = styled.div`
  width: 20%;
  height: 100%;
  background: #fcfcfc;
  background: #2b004f;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media (max-width: 1100px) {
    display: none;
  }
`;

export const SidebarTitle = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`;

export const SidebarBody = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0px 50px;
  padding-top: 100px;
  p {
    text-decoration: none;
    font-size: 14px;
    margin: 5px 0px;
    cursor: pointer;
    color: #fff;
  }

  .subitem {
    padding-left: 15px;
  }
`;

export const MainArea = styled.div`
  width: 80%;
  height: 100%;
  max-height: 100%;
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  box-sizing: border-box;
  padding: 40px;
  overflow-y: scroll;
  overflow-x: hidden;
  h2 {
    margin: 5px 0px;
    margin-top: 40px;
  }
  h3 {
    margin: 5px 0px;
    margin-top: 20px;
  }
  p {
    margin: 6px 0px;
    margin-bottom: 12px;
    text-align: justify;
  }
  code {
    width: 100%;
    box-sizing: border-box;
    padding: 5px 10px;
    background: #f5f5f5;
    border-radius: 5px;
    word-wrap: break-word;
  }

  b {
    cursor: pointer;
    color: #3d5191;
  }

  table {
    width: 100% !important;
    box-sizing: border-box;
    padding: 5px 10px;
    background: #f5f5f5;
    border-radius: 5px;
  }

  @media (max-width: 1100px) {
    width: 100%;
  }
  table {
    margin-top: 10px;
    width: 50%;
  }
  thead {
    font-weight: bold;
  }
  td {
    border-bottom: 1px solid #eee;
    padding: 10px 0px;
    font-size: 14px;
  }
`;

export const CodeBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  code {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const Badge = styled.div`
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.type === 'post' ? '#2587f0' : '#2ecc71')};
  border-radius: 5px;
  font-size: 11px;
  color: #fff;
  margin: 5px;
  box-sizing: border-box;
  padding: 2px 10px;
`;

export const ResponseBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f5f5f5;
  /* min-height: 50px; */
  margin-top: 5px;
  box-sizing: border-box;
  padding: 20px;
`;

export const Divider = styled.hr`
  width: 100%;
  border: 1px solid #eee;
  margin: 30px 0px;
`;

export const Logo = styled.img`
  width: 160px;
`;
