import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

import Home from './screens/Home';
import Terms from './screens/Terms';
import Docs from './screens/Docs';
import Jobs from './screens/Job';
import PrintGeneralConditions from './screens/PrintGeneralConditions';
import PartnerLead from './screens/PartnerLead';
import QuotationCorporate from './screens/QuotationCorporate';
import QuotationGlobus from './screens/QuotationGlobus';
import QuotationGlobusNew from './screens/NewQuotation';
import PrintQuotation from './screens/PrintQuotation';

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      Cookies.get('spltrsk-authtoken') ? (
        <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <PublicRoute exact path="/" component={QuotationGlobusNew} />
      {/* <PublicRoute exact path="/" component={Home} /> */}
      {/* <PublicRoute path="/termos" component={Terms} />
      <PublicRoute path="/trabalhe-conosco" component={Jobs} />
      <PublicRoute path="/api/docs" component={Docs} /> */}
      {/* <PublicRoute path="/cotacao/globus" component={QuotationGlobus} /> */}
      {/* <PublicRoute path="/cotacao/globus" component={QuotationGlobusNew} /> */}
      <PublicRoute path="/imprimir-cotacao/:id" component={PrintQuotation} />
      {/* <PublicRoute path="/corporate/simulacao" component={QuotationCorporate} /> */}
      {/* <PublicRoute path="/novo-parceiro" component={PartnerLead} /> */}
      {/* <PublicRoute path="/condicoes-gerais" component={PrintGeneralConditions} /> */}
    </Switch>
  </BrowserRouter>
);

export default Routes;
