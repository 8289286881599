import styled from "styled-components";
import InputMask from "react-input-mask";

export const Closed = styled.div`
  display: flex;
  justify-content: flex-end;
  div {
    cursor: pointer;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0px 100px 20px 100px;
  align-items: center;
  outline: none;

  h2 {
    text-align: center;
    font-family: "Nunito";
    font-size: 30px;
    color: #19002a;
    line-height: 30px;
    margin: 0;
    margin-bottom: 40px;
  }
  div {
    width: 100%;
  }
  p {
    font-size: 12px;
    color: red;
    margin-top: -15px;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00eb84;
    border: none;
    width: 50%;
    border-radius: 50px;
    padding: 10px;
    color: #19002a;
    font-family: "Nunito";
    font-weight: 600;
    font-size: 14px;
    margin-top: 20px;
    cursor: pointer;
    transition: filter 0.2s;
    text-align: center;
    outline: none;

    &:hover {
      filter: brightness(0.9);
    }
  }

  @media (max-width: 700px) {
    padding: 0px 20px 20px 20px;
    h2 {
      font-size: 28px;
      line-height: 28px;
      margin-bottom: 20px;
    }
  }
`;

export const Input = styled(InputMask)`
  height: 25px;
  width: 100%;
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  margin-bottom: 20px;
  outline: 0;
  border-width: 0 0 2px;
  border-color: #c9c9c9;

  &&::placeholder {
    color: gray;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 100px 70px 100px;
  align-items: left;
  justify-content: center;
  h2 {
    font-family: "Nunito";
    font-weight: 700;
    font-size: 34px;
    color: #00eb84;
    margin: 0;
  }
  p {
    font-family: "Nunito";
    color: gray;
    font-size: 18px;
  }
  span {
    font-weight: 600;
    color: #19002a;
  }

  @media (max-width: 700px) {
    padding: 0px 20px 20px 20px;
    .pulaPagina {
      display: none;
    }
    h2 {
      font-size: 28px;
      line-height: 28px;
      margin-bottom: 20px;
    }
  }
`;
