import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden; 
  }
  body {
    font-family: 'Nunito', sans-serif;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    color: #000;
  }

  .react-modal-overlay {
    background: rgba(25, 0, 42, 0.9);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .react-modal-overlay-2 {
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .react-modal-content {
    width: 100%;
    max-width: 576px;
    background: white;
    padding: 20px ;
    position: relative;
    border-radius: 20px;
    outline: none;
    @media (max-width: 700px) {
      width: 80%;
    }
  }
  .react-modal-content-2 {
    width: 100%;
    max-width: 50%;
    background: white;
    padding: 20px 30px;
    position: relative;
    border-radius: 20px;
    outline: none;
    @media (max-width: 700px) {
      width: 80%;
    }
  }
`;
