/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import api from 'api';
import { Stepper } from 'components';
import { Centralizer, NewQuotationContainer, Title } from './style';
import { MdChevronLeft, MdRestartAlt } from 'react-icons/md';
import { IconButton, Tooltip } from '@material-ui/core';
import { PlateStep, VehicleModelStep, VehicleUseStep, AddressStep, CoverageStep, ProposalStep, EmissionStep } from './Steps';
import { toast } from 'react-toastify';
import { formatBirthdate } from 'utils/birthDate';
import { API_TOKEN } from 'utils/token';

const NewQuotation = ({ history }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [quotationData, setQuotationData] = useState({ comission: 0.1 });
  const [quotationResponse, setQuotationResponse] = useState({});
  const [proposalResponse, setProposalResponse] = useState({});
  const [loadingQuotation, setLoadingQuotation] = useState(false);
  const [loadingProposal, setLoadingProposal] = useState(false);
  const [vehicleAccepted, setvehicleAccepted] = useState(false);

  const steps = [
    { id: 1, name: 'Placa do veículo' },
    { id: 2, name: 'Modelo do veículo' },
    { id: 3, name: 'Tipo de uso do veículo' },
    { id: 4, name: 'Endereço do proprietário' },
    { id: 5, name: 'Coberturas' },
  ];

  const nextStep = () => {
    if (currentStep === steps.length - 1) {
      setCurrentStep(steps.length);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const previousStep = () => {
    if (currentStep === 1) {
      setCurrentStep(1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const goToStep = (step) => {
    setCurrentStep(step);
  };

  const handleQuotation = async (receivedCoverages) => {
    const products =
      receivedCoverages?.length > 0 ? receivedCoverages?.map((i) => i.id) : quotationData?.products?.map((i) => i.id);

    if (products?.length > 0) {
      setLoadingQuotation(true);
      try {
        const auth = await api({
          method: 'post',
          url: `/api/auth`,
          data: {
            apikey: '078beaf1a94e23c7de3b309e46644101',
            apisecret: '4774cdfce1e0f38d29ddb0ceb3a87cb7',
          },
        });

        const response = await api({
          method: 'post',
          url: `/quotation-corporate-feature`,
          data: {
            ...quotationData,
            products,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: auth.data?.token,
          },
        });
        setQuotationResponse(response?.data);
        setLoadingQuotation(false);
      } catch (e) {
        console.log({ quotationError: e });
        setLoadingQuotation(false);
      }
    }
  };

  const restartQuotation = () => {
    setQuotationData({});
    setProposalResponse({});
    setCurrentStep(1);
  };

  const handleProposal = async () => {
    setLoadingProposal(true);
    const requestData = {
      comission: quotationData?.comission,
      discount: quotationData?.discount,
      value: parseFloat(quotationResponse?.final_value),
      client: {
        name: quotationData?.client?.name?.toUpperCase(),
        cpf: quotationData?.client?.document?.replaceAll('-', '').replaceAll('.', ''),
        phone: quotationData?.client?.phone?.replace(/\D/g, ''),
        email: quotationData?.client?.email,
        gender: quotationData?.client?.gender,
        birthdate: formatBirthdate(quotationData?.client?.birthdate),
      },
      vehicle: {
        id_color: Number(quotationData?.vehicle?.id_color),
        plate: quotationData?.vehicle?.plate?.replace('-', ''),
        id_vehicle_type: quotationData?.vehicle?.type,
        id_brand: quotationData?.vehicle?.id_brand,
        brand: quotationData?.vehicle?.brand,
        id_model: quotationData?.vehicle?.id_model,
        model: quotationData?.vehicle?.model,
        year_model: quotationData?.vehicle?.year,
        value: quotationData?.vehicle?.value,
        id_fuel: quotationData?.vehicle?.id_fuel,
        fuel: quotationData?.vehicle?.fuel,
        fipe_code: quotationData?.vehicle?.fipe_code,
        id_vehicle_use: quotationData?.vehicle?.id_vehicle_use,
        armored: quotationData?.vehicle?.armored,
        manufacture_year: quotationData?.vehicle?.build_year,
        chassi: quotationData?.vehicle?.chassi?.replaceAll(' ', ''),
        zero_km: quotationData?.vehicle?.zero_km,
      },
      address: {
        zip_code: quotationData?.address?.zip_code?.replaceAll('-', '').replaceAll('.', ''),
        street: quotationData?.address?.street,
        number: quotationData?.address?.number,
        complement: quotationData?.address?.complement,
        district: quotationData?.address?.district,
        city: quotationData?.address?.city,
        state: quotationData?.address?.state,
      },
      products: quotationData?.products?.map((i) => i?.id),
    };

    try {
      const resp = await api({
        method: 'POST',
        url: `/proposal`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: API_TOKEN,
        },
        data: requestData,
        json: true,
      });
      setProposalResponse(resp.data);
      toast.success('Proposta criada com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingProposal(false);
      nextStep();
    } catch (err) {
      setLoadingProposal(false);

      console.log('createProposalError', err);
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  useEffect(() => {
    handleQuotation();
  }, []);

  return (
    <NewQuotationContainer>
      <Centralizer>
        <Title>
          <div>Cotação Corporate</div>
          {currentStep > 1 && (
            <Tooltip title="Reiniciar cotação">
              <IconButton size="small" style={{ marginRight: 10 }} onClick={restartQuotation}>
                <MdRestartAlt size={24} />
              </IconButton>
            </Tooltip>
          )}
        </Title>

        <div>
          {
            <Stepper
              goToStep={goToStep}
              steps={steps}
              currentStep={currentStep}
              previousStep={previousStep}
              quotationData={quotationData}
              quotationResponse={quotationResponse}
              proposalResponse={proposalResponse}
              vehicleAccepted={vehicleAccepted}
            />
          }
        </div>

        <div>
          {currentStep === 1 && (
            <PlateStep
              quotationData={quotationData}
              setQuotationData={setQuotationData}
              nextStep={nextStep}
              previousStep={previousStep}
            />
          )}
          {currentStep === 2 && (
            <VehicleModelStep
              quotationData={quotationData}
              setQuotationData={setQuotationData}
              nextStep={nextStep}
              previousStep={previousStep}
              vehicleAccepted={vehicleAccepted}
              setvehicleAccepted={setvehicleAccepted}
            />
          )}
          {currentStep === 3 && (
            <VehicleUseStep
              quotationData={quotationData}
              setQuotationData={setQuotationData}
              nextStep={nextStep}
              previousStep={previousStep}
            />
          )}
          {currentStep === 4 && (
            <AddressStep
              quotationData={quotationData}
              setQuotationData={setQuotationData}
              nextStep={nextStep}
              previousStep={previousStep}
            />
          )}
          {currentStep === 5 && (
            <CoverageStep
              quotationData={quotationData}
              setQuotationData={setQuotationData}
              nextStep={nextStep}
              previousStep={previousStep}
              quotationResponse={quotationResponse}
              loadingQuotation={loadingQuotation}
              proposalResponse={proposalResponse}
              handleQuotation={handleQuotation}
            />
          )}
          {currentStep === 6 && (
            <ProposalStep
              quotationData={quotationData}
              setQuotationData={setQuotationData}
              nextStep={nextStep}
              previousStep={previousStep}
              quotationResponse={quotationResponse}
              loadingQuotation={loadingQuotation}
              restartQuotation={restartQuotation}
              handleProposal={handleProposal}
              loadingProposal={loadingProposal}
              proposalResponse={proposalResponse}
            />
          )}
          {currentStep === 7 && (
            <EmissionStep
              quotationData={quotationData}
              setQuotationData={setQuotationData}
              nextStep={nextStep}
              previousStep={previousStep}
              quotationResponse={quotationResponse}
              restartQuotation={restartQuotation}
              proposalResponse={proposalResponse}
            />
          )}
        </div>
      </Centralizer>
    </NewQuotationContainer>
  );
};

export default NewQuotation;
