/* eslint-disable jsx-a11y/alt-text */
import ReactLoading from 'react-loading';
import { Input } from 'components';
import { Container, SectionTitle } from '../../style';
import { useState } from 'react';
import { loadingColor } from 'styles/colorProvider';
import PlateIcon from 'assets/plate-2.png';

const PlateStep = ({ quotationData, setQuotationData, nextStep }) => {
  const [loading, setLoading] = useState(false);

  const handleChangePlate = async (e) => {
    const plate = e.target.value;
    setQuotationData({ ...quotationData, vehicle: { ...quotationData.vehicle, plate } });

    if (plate.length === 8) {
      try {
        setLoading(true);
        nextStep();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const notPlatedVehicleButtonHandleClick = () => {
    setQuotationData({ ...quotationData, vehicle: { ...quotationData.vehicle, zero_km: true } });
    nextStep();
  };

  return (
    <Container>
      <SectionTitle>Insira a placa do veículo</SectionTitle>
      <>
        <Input
          icon={<img src={PlateIcon} style={{ width: 25 }} />}
          label="Placa"
          value={quotationData?.vehicle?.plate}
          disableLabel
          mask="aaa-9*99"
          placeholder="Placa"
          onChange={handleChangePlate}
          endIcon={loading && <ReactLoading color={loadingColor} type="spin" />}
        />

        <p
          onClick={notPlatedVehicleButtonHandleClick}
          style={{ fontSize: 11, marginBottom: 15, textDecoration: 'underline', cursor: 'pointer' }}
        >
          Veículo não emplacado
        </p>
      </>
    </Container>
  );
};

export default PlateStep;
