import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { secondary } from 'styles/colorProvider';
import { MdChevronLeft } from 'react-icons/md';

const StepperContainer = styled.div`
  display: flex;
  margin: 20px 0;
`;

const StepTitleMobile = styled.div`
  display: flex;
  align-items: center;
`;

const StepBox = styled.div`
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;

  p {
    font-size: 12px;
    font-weight: bold;
    color: gray;
  }
`;

const StepTitle = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: gray;

  @media (max-width: 1000px) {
    text-align: center;
    display: ${(props) => !props.selected && 'none'};
  }
`;

const PinContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;

  ::before,
  ::after {
    content: '';
    display: ${({ isChecked }) => !isChecked && 'none'};
    position: absolute;
    top: 0;
    height: 100%;
    width: ${({ isChecked }) => (isChecked ? '400px' : '100%')};
    border-radius: 50px;
    box-shadow: 0px 1px 2px 0 gray;
    background: #fafafa;
  }
  ::before {
    right: 0;
    width: ${({ isFirstChild }) => isFirstChild && '100%'};
    z-index: 1;
  }
  ::after {
    display: ${({ nextIsChecked }) => !nextIsChecked && 'none'};
    left: 0;
    width: ${({ isLastChild }) => isLastChild && '100%'};
    box-shadow: ${({ isFirstChild, isChecked }) => {
      if (!isFirstChild && isChecked) {
        return '3px 1px 2px 0 gray';
      }
    }};
    z-index: 2;
  }

  span {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: ${({ isChecked }) => (isChecked ? secondary : '#7398bb')};
    z-index: 5;
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  }
`;

const Stepper = ({ steps, currentStep, goToStep, quotationData, quotationResponse, proposalResponse, vehicleAccepted }) => {
  const navigate = (step) => {
    goToStep(step);
  };

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1000);
    };

    handleResize(); // Verificar o tamanho da tela inicialmente

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isStepClickable = (step) => {
    let resp;
    if (step === 1) resp = true;

    if (step === 2) {
      if (quotationData?.vehicle?.plate || quotationData?.vehicle?.zero_km) {
        resp = true;
      } else {
        resp = false;
      }
    }

    if (step === 3) {
      if (quotationData?.vehicle?.value && !vehicleAccepted === false) {
        resp = true;
      } else {
        resp = false;
      }
    }

    if (step === 4) {
      if (quotationData?.vehicle?.id_vehicle_use) {
        resp = true;
      } else {
        resp = false;
      }
    }

    if (step === 5) {
      if (quotationData?.address?.zip_code) {
        resp = true;
      } else {
        resp = false;
      }
    }

    if (step === 6) {
      if (quotationResponse?.final_value) {
        resp = true;
      } else {
        resp = false;
      }
    }

    if (step === 7) {
      if (proposalResponse?.response?.id) {
        resp = true;
      } else {
        resp = false;
      }
    }

    return resp;
  };

  // get currentStep
  const currentStepName = steps?.find((step) => step?.id === currentStep)?.name;

  return isLargeScreen ? (
    <StepperContainer>
      {steps.map((step) => {
        const clickable = isStepClickable(step?.id);
        return (
          <StepBox key={step?.id}>
            <PinContainer
              clickable={clickable}
              isChecked={currentStep >= step?.id}
              isFirstChild={step?.id === 1}
              isLastChild={step?.id === steps?.lenght - 1}
              nextIsChecked={currentStep > step.id}
            >
              <span clickable={clickable} onClick={() => clickable && navigate(step?.id)} />
            </PinContainer>
            <p>{step?.name}</p>
          </StepBox>
        );
      })}
    </StepperContainer>
  ) : (
    <StepTitleMobile>
      {/* Show return button only if is not the first child */}
      {currentStep > 1 && (
        <div>
          <IconButton size="small" style={{ marginRight: 20 }} onClick={() => navigate(currentStep - 1)}>
            <MdChevronLeft size={24} />
          </IconButton>
        </div>
      )}
      <h4>{currentStepName}</h4>
    </StepTitleMobile>
  );
};

export default Stepper;
