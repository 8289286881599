import React from 'react';
import { Container, Left, Right } from './styles';
import Tooltip from '@mui/material/Tooltip';
import { formatCurrency } from 'react-data-formatter';

function Percent({ value, insurance, label }) {
  return (
    <Container>
      <Tooltip title={`Módulo ${(insurance * 100).toFixed(0)}%`} placement="left" arrow>
        <Left width={'100%'}>
          <small>{label}</small>
          <p>{formatCurrency(value * insurance || 0)}</p>
        </Left>
      </Tooltip>
    </Container>
  );
}

export default Percent;
