import ReactLoading from 'react-loading';
import api from 'api';
import axios from 'axios';
import { useState } from 'react';
import { Alert } from '@material-ui/lab';
import { BiLocationPlus } from 'react-icons/bi';
import { Container, SectionTitle } from '../../style';
import { AddressDataContainer, AddressDataInputContainer } from './style';
import { loadingColor } from 'styles/colorProvider';
import { Input, Button } from 'components';
import { API_TOKEN } from 'utils/token';

export const AddressStep = ({ quotationData, setQuotationData, nextStep }) => {
  const [address, setAddress] = useState(
    quotationData?.address || {
      zip_code: '',
      street: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      state: '',
    }
  );
  const [loading, setLoading] = useState(false);
  const [addressError, setAddressError] = useState(false);

  const handleChangeCep = async (e) => {
    const stringCep = e.target.value.replaceAll('-', '').replaceAll('.', '');

    setLoading(true);

    if (stringCep.length === 8) {
      setAddressError(false);
      try {
        const data = await axios.get(`https://viacep.com.br/ws/${stringCep}/json/`);
        const currentAddress = {
          zip_code: stringCep,
          street: data?.data?.logradouro,
          district: data?.data?.bairro,
          city: data?.data?.localidade,
          state: data?.data?.uf,
        };
        const response = await api({
          method: 'POST',
          url: `/address-accepted`,
          data: { address: currentAddress },
          headers: {
            'Content-Type': 'application/json',
            Authorization: API_TOKEN,
          },
        });
        setAddress(currentAddress);
        setAddressError(!response.data);
      } catch (err) {
        console.log('handleChangeCepError', err);
      }
    }
    setLoading(false);
  };

  return (
    <Container>
      <SectionTitle>Endereço do proprietário</SectionTitle>
      {addressError && (
        <Alert style={{ marginBottom: 20 }} severity="error">
          Oops! Infelizmente não existe cobertura disponível para o endereço informado!
        </Alert>
      )}

      <AddressDataContainer>
        <AddressDataInputContainer>
          <Input
            label="CEP"
            icon={<BiLocationPlus />}
            height="30px"
            mask="99.999-999"
            placeholder="CEP"
            value={address?.cep}
            onChange={handleChangeCep}
            endIcon={loading && <ReactLoading color={loadingColor} type="spin" />}
          />
          <>
            <Input
              height="30px"
              label="Logradouro"
              value={address.street}
              onChange={(event) => setAddress({ ...address, street: event.target.value })}
            />
            <Input
              height="30px"
              label="Número"
              value={address?.number}
              onChange={(event) => setAddress({ ...address, number: String(event.target.value) })}
            />
            <Input
              height="30px"
              label="Complemento (opcional)"
              value={address.complement}
              onChange={(event) => setAddress({ ...address, complement: event.target.value })}
            />
          </>
        </AddressDataInputContainer>
        <AddressDataInputContainer>
          <Input
            height="30px"
            label="Bairro"
            value={address.district}
            onChange={(event) => setAddress({ ...address, district: event.target.value })}
          />

          <Input
            height="30px"
            label="Cidade"
            value={address.city}
            onChange={(event) => setAddress({ ...address, city: event.target.value })}
          />

          <Input
            height="30px"
            label="Estado"
            value={address.state}
            onChange={(event) => setAddress({ ...address, state: event.target.value })}
          />
        </AddressDataInputContainer>
      </AddressDataContainer>

      {!addressError && (
        <Button
          disabled={
            !address?.zip_code ||
            !address?.street ||
            !address?.number ||
            !address?.district ||
            !address?.city ||
            !address?.state ||
            addressError
          }
          buttonColor="#fe9501"
          buttonBoxShadowColor="#fe9501"
          style={{ marginLeft: 0 }}
          height="35px"
          onClick={() => {
            setQuotationData({ ...quotationData, address: address });
            nextStep();
          }}
        >
          Continuar
        </Button>
      )}
    </Container>
  );
};

export default AddressStep;
