import api from 'api/index';
import Cookies from 'js-cookie';
import SideBar from '../CoverageStep/SideBar';
import { useState, useEffect, useCallback } from 'react';
import { cpf } from 'cpf-cnpj-validator';
import { RiCarFill } from 'react-icons/ri';
import { Input, Select } from 'components';
import { isBirthDateValid } from 'utils/birthDate';
import { Container, SectionTitle } from '../../style';
import { middleGray } from 'styles/colorProvider';
import { ConfirmDialog } from 'components/index';
import { ClientDataContainer, ClienteDataInputContainer, Content, Radio, VehicleDataContainer } from './style';
import { FaMotorcycle } from 'react-icons/fa';
import { formatCurrency } from 'react-data-formatter';
import settings from 'settings.json';
import { API_TOKEN } from 'utils/token';

const ProposalStep = ({
  quotationData,
  setQuotationData,
  quotationResponse,
  loadingProposal,
  handleProposal,
  proposalResponse,
}) => {
  const [discountPermission, setDiscountPermission] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [colorsList, setColorsList] = useState([]);
  const selectedColor = colorsList.find((currentColor) => {
    return currentColor.id === Number(quotationData?.vehicle?.id_color);
  });

  const clienteIsNotValid =
    !quotationData?.client?.name ||
    !quotationData?.client?.email ||
    !quotationData?.client?.gender ||
    !quotationData?.client?.phone ||
    !isBirthDateValid(quotationData?.client?.birthdate) ||
    !cpf.isValid(quotationData?.client?.document);

  const chassiAndColorIsNotvalid = !quotationData?.vehicle?.id_color || !quotationData?.vehicle?.chassi;

  const loadColors = useCallback(async () => {
    const response = await api.get(`/list-vehicle-colors`, {
      headers: {
        apiKey: 'a3607db1-530f-4565-93a1-7c2ff693c8db',
        apiSecret: 'f2b85508-8097-4adf-990a-6ba37b246fc1',
      },
    });
    setColorsList(response.data);
  }, [setColorsList]);

  const loadPermission = async () => {
    const verifyPermission = {
      method: 'GET',
      url: `/has-feature`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: API_TOKEN,
        feature: 'aplicar-desconto-apolice',
      },
      json: true,
    };

    const responsePermission = await api(verifyPermission);
    setDiscountPermission(responsePermission.data);
  };

  useEffect(() => {
    loadColors();
  }, [loadColors]);

  useEffect(() => loadPermission(), []);

  const [discountOptions, setDiscountOptions] = useState({
    discountType: 'value',
    discountInput: undefined,
    finalDiscountValue: undefined,
    finalDiscountPercent: undefined,
    finalValue: undefined,
  });

  const handleDiscount = (e) => {
    const discountValue = parseFloat(e.target.value.replace('R$ ', '').replace(',', '.'));

    if (discountOptions?.discountType === 'value') {
      const fDiscountValue = discountValue;
      const fDiscountPercent = discountValue / quotationResponse?.final_value;
      const fValue = quotationResponse?.final_value - fDiscountValue;
      setDiscountOptions({
        ...discountOptions,
        finalDiscountValue: fDiscountValue,
        finalDiscountPercent: fDiscountPercent,
        finalValue: fValue,
      });
      setQuotationData({ ...quotationData, discount: fDiscountPercent / 100 });
    }

    if (discountOptions?.discountType === 'percent') {
      const fDiscountValue = (discountValue / 100) * quotationResponse?.final_value;
      const fDiscountPercent = discountValue;
      const fValue = quotationResponse?.final_value - fDiscountValue;
      setDiscountOptions({
        ...discountOptions,
        finalDiscountValue: fDiscountValue,
        finalDiscountPercent: fDiscountPercent,
        finalValue: fValue,
      });
      setQuotationData({ ...quotationData, discount: fDiscountPercent / 100 });
    }
  };

  const host = window.location.hostname.split('.')[0];
  const isDiscountAllowed = settings.allowed_discount_partners.includes(host);

  return (
    <Container>
      <SectionTitle>Emitir proposta</SectionTitle>
      <Content style={{ width: '100%' }}>
        <ClientDataContainer>
          <h3>Dados do cliente</h3>
          <div>
            <ClienteDataInputContainer>
              <Input
                height="30px"
                label="Nome completo"
                value={quotationData?.client?.name}
                onChange={(event) =>
                  setQuotationData({ ...quotationData, client: { ...quotationData?.client, name: event.target.value } })
                }
                success={quotationData?.client?.name?.length > 5}
              />
              <Input
                height="30px"
                label="CPF"
                mask="999.999.999-99"
                value={quotationData?.client?.document}
                onChange={(event) =>
                  setQuotationData({ ...quotationData, client: { ...quotationData?.client, document: event.target.value } })
                }
                success={cpf.isValid(quotationData?.client?.document)}
                error={quotationData?.client?.document && !cpf.isValid(quotationData?.client?.document)}
              />
              <Input
                height="30px"
                label="Data de nascimento"
                mask="99/99/9999"
                value={quotationData?.client?.birthdate}
                onChange={(event) =>
                  setQuotationData({ ...quotationData, client: { ...quotationData?.client, birthdate: event.target.value } })
                }
                success={isBirthDateValid(quotationData?.client?.birthdate)}
                error={quotationData?.client?.birthdate && !isBirthDateValid(quotationData?.client?.birthdate)}
              />
            </ClienteDataInputContainer>
            <ClienteDataInputContainer>
              <Input
                height="30px"
                label="Telefone"
                mask="(99) 99999-9999"
                value={quotationData?.client?.phone}
                onChange={(event) =>
                  setQuotationData({ ...quotationData, client: { ...quotationData?.client, phone: event.target.value } })
                }
                success={quotationData?.client?.phone?.length === 15}
              />
              <Input
                height="30px"
                label="E-mail"
                value={quotationData?.client?.email}
                onChange={(event) =>
                  setQuotationData({ ...quotationData, client: { ...quotationData?.client, email: event.target.value } })
                }
                success={quotationData?.client?.email?.includes('@') && quotationData?.client?.email?.includes('.')}
                error={
                  quotationData?.client?.email &&
                  (!quotationData?.client?.email?.includes('@') || !quotationData?.client?.email?.includes('.'))
                }
              />
              <p style={{ fontSize: 12 }}>Sexo:</p>
              <div>
                <Radio
                  id="minput"
                  type="radio"
                  checked={quotationData?.client?.gender === 1}
                  onChange={() => setQuotationData({ ...quotationData, client: { ...quotationData?.client, gender: 1 } })}
                />{' '}
                <label htmlFor="minput">Masculino</label>
              </div>
              <div>
                <Radio
                  id="finput"
                  type="radio"
                  checked={quotationData?.client?.gender === 2}
                  onChange={() => setQuotationData({ ...quotationData, client: { ...quotationData?.client, gender: 2 } })}
                />{' '}
                <label htmlFor="finput">Feminino</label>
              </div>
            </ClienteDataInputContainer>
          </div>

          <div style={{ display: discountPermission && isDiscountAllowed ? 'flex' : 'none', flexDirection: 'column' }}>
            <h3>Desconto</h3>
            <section className="discount-area">
              <Select
                value={discountOptions?.discountType}
                onChange={(e) => setDiscountOptions({ ...discountOptions, discountType: e.target.value })}
                label="Tipo de desconto"
                height="30px"
                style={{ margin: 0 }}
              >
                <option value="value">Valor</option>
                <option value="percent">Porcentagem</option>
              </Select>
              <Input
                style={{ margin: 0 }}
                height="30px"
                label="Percentual de desconto"
                numeric
                price={discountOptions?.discountType === 'value'}
                value={discountOptions?.discountInput}
                onChange={handleDiscount}
              />

              <>
                <p style={{ margin: 0, fontSize: 12, marginTop: 10 }}>
                  Percentual de desconto:{' '}
                  {discountOptions?.finalDiscountPercent ? discountOptions?.finalDiscountPercent?.toFixed(1) : 0}%{' '}
                </p>
                <p style={{ margin: 0, fontSize: 12 }}>
                  Valor do desconto:
                  {formatCurrency(discountOptions?.finalDiscountValue || 0)}
                </p>
                <p style={{ margin: 0, fontSize: 12 }}>
                  Valor final com desconto: {formatCurrency(discountOptions?.finalValue || 0)}
                </p>
              </>
            </section>
          </div>
        </ClientDataContainer>

        <VehicleDataContainer>
          <h3>Dados do veículo</h3>
          <p className="proposalInfo">Placa: {quotationData?.vehicle?.plate?.toUpperCase() || 'Sem placa'}</p>
          <p className="proposalInfo">Marca: {quotationData?.vehicle?.brand}</p>
          <p className="proposalInfo">Modelo: {quotationData?.vehicle?.model}</p>
          <p className="proposalInfo">Tipo de veículo: {quotationData?.vehicle?.type}</p>
          <p className="proposalInfo">
            Ano fabricação/modelo: {quotationData?.vehicle?.build_year}/{quotationData?.vehicle?.year}
          </p>
          <Input
            height="30px"
            label="Chassi"
            mask="*** ****** ** ******"
            value={quotationData?.vehicle?.chassi}
            onChange={(event) =>
              setQuotationData({ ...quotationData, vehicle: { ...quotationData?.vehicle, chassi: event.target.value } })
            }
            success={quotationData?.vehicle?.chassi?.length === 20}
          />

          <Select
            label="Cor"
            icon={
              quotationData?.vehicle?.type === 7 ? (
                <FaMotorcycle color={selectedColor?.color || middleGray} />
              ) : (
                <RiCarFill color={selectedColor?.color || middleGray} />
              )
            }
            placeholder="Selecione a cor"
            value={quotationData?.vehicle?.id_color}
            onChange={(event) => {
              setQuotationData({ ...quotationData, vehicle: { ...quotationData?.vehicle, id_color: event.target.value } });
            }}
            onload={(event) => event?.target?.value}
          >
            <option value="">Selecione</option>
            {colorsList.map((color) => (
              <option value={color.id} key={`color-id-${color.id}`}>
                {color.name}
              </option>
            ))}
          </Select>
        </VehicleDataContainer>

        <SideBar
          quotationData={quotationData}
          setQuotationData={setQuotationData}
          quotationResponse={quotationResponse}
          proposalResponse={proposalResponse}
          type="proposal"
          confirmButtonIsDisable={clienteIsNotValid || chassiAndColorIsNotvalid}
          confirmButtonHandleClick={() => {
            setDialogIsOpen(true);
          }}
        />

        <ConfirmDialog
          open={dialogIsOpen}
          title="Enviar proposta?"
          content="Tem certeza que deseja enviar a proposta?"
          onConfirm={handleProposal}
          onCancel={() => setDialogIsOpen(false)}
          loading={loadingProposal}
        />
      </Content>
    </Container>
  );
};

export default ProposalStep;
