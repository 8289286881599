import styled from 'styled-components';
import { middleGray } from 'styles/colorProvider';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;

  .form {
    column-gap: 25px;
    row-gap: 15px;
  }

  .input-radio {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    width: 230px;
    border: 1px solid ${middleGray};
    padding: 0px 5px;
    border-radius: 10px;
    label {
      font-size: 12px;
    }
  }
`;

export const FilesContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;

  & > :first-child {
    margin-right: 50px;
  }

  @media (max-width: 950px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > :first-child {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;
