/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Select, InfoText } from 'components';
import { Container } from './style';

const PaymentData = ({ quotationData, setQuotationData }) => {
  return (
    <Container>
      <div className="proposal-section" style={{ marginTop: 15 }}>
        <h3>Forma de pagamento</h3>
        <div className="form">
          <Select
            label="Forma de pagamento"
            height="30px"
            width="230px"
            style={{ marginTop: 20, marginRight: 50 }}
            value={quotationData?.payment?.pay_method}
            onChange={(e) => {
              setQuotationData({ ...quotationData, payment: { ...quotationData.payment, pay_method: e.target.value } });
            }}
            error={!quotationData?.payment?.pay_method}
            success={quotationData?.payment?.pay_method}
          >
            <option value="">Selecione</option>
            <option value={1}>Cartão de crédito</option>
            <option value={2}>PIX</option>
            <option value={3}>Boleto</option>
          </Select>
        </div>

        {quotationData?.payment?.pay_method === '1' && (
          <div className="form" style={{ marginTop: 15 }}>
            <Input
              label="Número do cartão"
              height="30px"
              width="230px"
              style={{ marginRight: 50, marginTop: 20 }}
              number
              mask="9999 9999 9999 9999"
              value={quotationData?.payment?.number}
              onChange={(event) =>
                setQuotationData({
                  ...quotationData,
                  payment: { ...quotationData?.payment, number: event.target.value },
                })
              }
              success={quotationData?.payment?.number?.length === 19}
              error={!quotationData?.payment?.number || quotationData?.payment?.number?.length < 19}
            />

            <Input
              label="Nome do titular"
              height="30px"
              width="230px"
              style={{ marginRight: 50, marginTop: 20 }}
              value={quotationData?.payment?.name}
              onChange={(event) =>
                setQuotationData({
                  ...quotationData,
                  payment: { ...quotationData?.payment, name: event.target.value },
                })
              }
              success={quotationData?.payment?.name?.length > 5}
              error={!quotationData?.payment?.name || quotationData?.payment?.name?.length <= 5}
            />
            <Input
              number
              height="30px"
              width="230px"
              style={{ marginRight: 50, marginTop: 20 }}
              label="Validade do cartão"
              mask="99/99"
              placeholder="MM/AA"
              value={quotationData?.payment?.expiry}
              onChange={(event) =>
                setQuotationData({
                  ...quotationData,
                  payment: { ...quotationData?.payment, expiry: event.target.value },
                })
              }
              success={quotationData?.payment?.expiry?.length === 5}
              error={!quotationData?.payment?.expiry || quotationData?.payment?.expiry?.length < 5}
            />
            <Input
              number
              mask="999"
              height="30px"
              width="230px"
              style={{ marginRight: 50, marginTop: 20 }}
              placeholder="CVC/CVV"
              label="CVC/CVV"
              value={quotationData?.payment?.cvc}
              onChange={(event) =>
                setQuotationData({
                  ...quotationData,
                  payment: { ...quotationData?.payment, cvc: event.target.value },
                })
              }
              success={quotationData?.payment?.cvc?.length === 3}
              error={!quotationData?.payment?.cvc || quotationData?.payment?.cvc?.length < 3}
            />
          </div>
        )}
      </div>
    </Container>
  );
};

export default PaymentData;
