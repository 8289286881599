import styled from 'styled-components';
import { primary, secondary } from '../../styles/colorProvider';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: #eee;
  margin: 5px 0px;
  margin-bottom: 15px;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: ${secondary};
  width: ${(props) => props.width};
  transition: all ease-in-out 0.3s;
  small {
    font-size: 10px !important;
    color: ${primary}!important;
  }
  p {
    color: ${primary}!important;
    font-size: 12px !important;
    font-weight: bolder;
  }
  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: ${primary} !important;
  width: ${(props) => props.width};
  transition: all ease-in-out 0.3s;
  small {
    font-size: 10px !important;
    color: ${secondary} !important;
  }
  p {
    color: ${secondary} !important;
    font-size: 12px !important;
    font-weight: bolder;
  }
  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
`;
