import React from 'react';

import logo from '../../assets/logo.png';
import simbolo from '../../assets/simboloLogoBranca.PNG';
import { CgLogIn } from 'react-icons/cg';
import { RiInstagramFill } from 'react-icons/ri';
import { RiLinkedinBoxFill } from 'react-icons/ri';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { Button } from 'components';
import { Container, TopBar, Header, HeaderText, Line, Line2, Line3, Logo, Nav, Login, Footer, Simbolo } from './styles';

Modal.setAppElement('#root');

function Index() {
  const history = useHistory();

  return (
    <>
      <Container>
        <TopBar>
          <Line>
            <Logo
              src={logo}
              onClick={() => {
                history.push('/');
              }}
            />
          </Line>
        </TopBar>
        <Header>
          <Line2>
            <HeaderText>
              <h1>
                Split Risk.
                <br />
                Uma nova forma <br />
                de fazer seguro.
              </h1>
              {/* <h2>Ideal para você e seu veículo.</h2> */}
              <div className="button-area">
                <Button
                  onClick={() => window.open('https://app.pipefy.com/public/form/VKLzaeV1')}
                  style={{ width: 300, margin: 0, fontSize: 18, marginTop: 35 }}
                >
                  Comunicar Ocorrência de Sinistro
                </Button>
              </div>
            </HeaderText>
          </Line2>
        </Header>
        <Footer>
          <Line3>
            <div className="info">
              <Simbolo
                src={simbolo}
                onClick={() => {
                  history.push('/');
                }}
              />
              <p>
                Split Risk Seguradora S.A. <br />
                Processo Susep: 15414.618377/2020-87 <br />
                CNPJ: 43.505.273/0001-09
              </p>
            </div>
            <div>
              <h5
                onClick={() => {
                  window.open('https://app.pipefy.com/public/form/eIrrP4sT');
                }}
              >
                Credenciamento de Oficina
              </h5>
              <h5
                onClick={() => {
                  window.open('https://app.pipefy.com/public/form/9SuT_8TS');
                }}
              >
                Canal do Prestador de Serviço
              </h5>
              <h5
                onClick={() => {
                  window.open('https://app.pipefy.com/public/form/tqLBwqLx');
                }}
              >
                Canal do Parceiro
              </h5>
              <h5
                onClick={() => {
                  history.push('/trabalhe-conosco');
                }}
              >
                Faça parte do nosso time
              </h5>
              <h5
                onClick={() => {
                  history.push('/termos');
                }}
              >
                Termos de uso e políticas de privacidade
              </h5>
              <div className="social">
                <a href="https://www.instagram.com/splitrisk.app/" target="blank">
                  <RiInstagramFill size={20} />
                </a>
                <a href="https://br.linkedin.com/company/split-risk" target="blank">
                  <RiLinkedinBoxFill size={20} />
                </a>
              </div>
            </div>
          </Line3>
        </Footer>
      </Container>
    </>
  );
}

export default Index;
